import {
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { FlexRow } from './flex'

export const StandardTableContainer = styled(TableContainer)(({ theme }) => ({}))

export const StandardTable = styled(Table)(({ theme }) => ({}))

export const StandardTableHead = styled(TableHead)(({ theme }) => ({
    th: {
        fontSize: 20,

        '.MuiTableSortLabel-root .MuiTableSortLabel-icon': {
            color: theme.palette.custom.mustard.main,
        },
    },
}))

export const StandardTableBody = styled(TableBody)(({ theme }) => ({
    'tr:nth-of-type(odd) td': {
        background: theme.palette.custom.whitesmoke.main,

        ':first-of-type': {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
        },
        ':last-of-type': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
        },
    },
}))

export const CollapsableTableBody = styled(TableBody)(({ theme }) => ({
    'tr:nth-of-type(2n+2) td': {
        background: theme.palette.custom.whitesmoke.main,

        ':first-of-type': {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
        },
        ':last-of-type': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
        },
    },
}))

export const StandardTableRow = styled(TableRow)(({ theme }) => ({}))

export const StandardTableCell = styled(TableCell)(({ theme }) => ({
    height: 50,
    padding: '0 33px',

    '&.compact': {
        padding: '0 8px',
    },

    [`&.${tableCellClasses.head}`]: {
        border: 'none',
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        border: 'none',
    },
}))

export const CollapsableTableCell = styled(StandardTableCell)(({ theme }) => ({
    height: 'auto',
}))

export const StandardTableCellCheckbox = styled(StandardTableCell)(({ theme }) => ({
    padding: '0',
    textAlign: 'center',
}))

export const StandardTableToolbar = styled(FlexRow)(({ theme }) => ({
    padding: '32px 0',
}))
