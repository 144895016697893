import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callSubscriptions } from '../grpc/api'

const initialState: SubscriptionsState = {
    subscriptions: [],
    status: 'not_loaded',
    error: null,
}

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setSubscriptions: (state, action: PayloadAction<Subscription[]>) => {
            state.subscriptions = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchSubscriptions.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.subscriptions = action.payload
                state.error = null
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error
            })
    },
})

export const fetchSubscriptions = createAsyncThunk(
    'subscriptions/fetchSubscriptions',
    async (): Promise<Subscription[]> => {
        return (await callSubscriptions()).subscriptionList
    }
)

export const { setSubscriptions } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
