import { styled, Button, lighten, Link, IconButton } from '@mui/material'

const StandardButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    borderStyle: 'solid',
    borderColor: theme.palette.custom.mustard.main,
    borderWidth: 1,
    background: theme.palette.custom.mustard.main,
    boxShadow: 'none',
    borderRadius: 20,
    minWidth: 130,
    width: 160,
    height: 40,
    fontFamily: 'RegularAimsunBold',
    letterSpacing: 0.32,

    '&:hover': {
        background: lighten(theme.palette.custom.mustard.main, 0.4),
        borderColor: lighten(theme.palette.custom.mustard.main, 0.4),
        boxShadow: 'none',
    },

    '&:focus': {
        boxShadow: 'none',
    },

    '&.MuiButton-textInfo': {
        color: theme.palette.common.white,
        background: theme.palette.info.main,
        borderColor: theme.palette.info.main,

        '&:hover': {
            background: lighten(theme.palette.info.main, 0.4),
            borderColor: lighten(theme.palette.info.main, 0.4),
        },
    },

    '&.MuiButton-textSuccess': {
        color: theme.palette.common.white,
        background: theme.palette.success.main,
        borderColor: theme.palette.success.main,

        '&:hover': {
            background: lighten(theme.palette.success.main, 0.4),
            borderColor: lighten(theme.palette.success.main, 0.4),
        },
    },

    '&.MuiButton-textWarning': {
        color: theme.palette.common.white,
        background: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,

        '&:hover': {
            background: lighten(theme.palette.warning.main, 0.4),
            borderColor: lighten(theme.palette.warning.main, 0.4),
        },
    },

    '&.MuiButton-textError': {
        color: theme.palette.common.white,
        background: theme.palette.error.main,
        borderColor: theme.palette.error.main,

        '&:hover': {
            background: lighten(theme.palette.error.main, 0.4),
            borderColor: lighten(theme.palette.error.main, 0.4),
        },
    },

    '&.MuiButton-outlined': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        background: 'transparent',

        '&:hover': {
            //color: lighten(theme.palette.primary.main, 0.4),
            //borderColor: lighten(theme.palette.primary.main, 0.4),

            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },

        '&.MuiButton-outlinedInfo': {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,

            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.info.main,
                borderColor: theme.palette.info.main,
            },
        },

        '&.MuiButton-outlinedSuccess': {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,

            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.success.main,
                borderColor: theme.palette.success.main,
            },
        },

        '&.MuiButton-outlinedWarning': {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,

            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.warning.main,
                borderColor: theme.palette.warning.main,
            },
        },

        '&.MuiButton-outlinedError': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,

            '&:hover': {
                color: theme.palette.common.white,
                background: theme.palette.error.main,
                borderColor: theme.palette.error.main,
            },
        },

        '&:disabled': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.custom.lightGrey.main,
            color: theme.palette.custom.lightGrey.main,
        },
    },

    '&:disabled': {
        letterSpacing: 0.64,
        backgroundColor: 'transparent',
        borderColor: theme.palette.custom.lightGrey.main,
        color: theme.palette.custom.lightGrey.main,
        fontFamily: theme.typography.fontFamily,
    },
}))

export const FitStandardButton = styled(StandardButton)(({ theme }) => ({
    width: 'fit-content',
}))

export const LinkButton = styled(Link)(({ theme }) => ({
    color: theme.palette.custom.blue.main,
    cursor: 'pointer',
    fontFamily: 'RegularAimsunMedium',
    textDecoration: 'none',
    letterSpacing: 0.32,
}))

export const LinkButtonAlt = styled(Link)(({ theme }) => ({
    color: theme.palette.custom.mustard.main,
    cursor: 'pointer',
    fontFamily: 'RegularAimsunMedium',
    textDecoration: 'none',
    letterSpacing: 0.32,
}))

export const StandardIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.custom.mustard.main,
}))

export default StandardButton
