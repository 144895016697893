import { styled, Tab, Tabs } from '@mui/material'

export const StandardTabs = styled(Tabs)(({ theme }) => ({
    height: 42,
    minHeight: 42,

    '& .MuiTabs-flexContainer': {
        gap: 40,
    },

    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.custom.mustard.main,
        height: 3,
    },
}))

export const StandardTab = styled(Tab)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
    minHeight: 46,
    padding: 0,

    '.MuiBadge-root': {
        margin: 0,
        marginLeft: 20,
        marginRight: 15,
    },
}))

export const BigTabs = styled(StandardTabs)(({ theme }) => ({
    height: 30,
    minHeight: 30,

    '& .MuiTabs-flexContainer': {
        gap: 50,
    },
}))

export const BigTab = styled(StandardTab)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 400,
    minHeight: 30,
    height: 30,
    padding: 0,

    '.MuiBadge-root': {
        margin: 0,
        marginLeft: 20,
        marginRight: 15,

        '.MuiBadge-badge': {
            fontWeight: 800,
        },
    },
}))
