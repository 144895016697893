import { styled, Snackbar } from '@mui/material'

const StandardSnackbar = styled(Snackbar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 300,

    '@media (min-width: 600px)': {
        left: 'auto',
        right: 24,
    },
}))

export default StandardSnackbar
