import { Avatar, styled } from '@mui/material'
import { FlexColumn, FlexRow } from './flex'
import { Header } from './header'

export const ProfileContainer = styled(FlexColumn)({
    width: 218,

    '.MuiTextField-root': {
        marginBottom: 15,
    },
})

export const ProfileHeader = styled(FlexRow)({
    alignItems: 'center',
    marginBottom: 32,

    '& > div': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    '& svg': {
        marginRight: 15,
    },
})

export const IconHeader = styled(Header)(({ theme }) => ({
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.25,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    marginBottom: 10,

    '& svg': {
        color: theme.palette.custom.mediumGrey.main,
        height: 15,
        width: 15,
        marginLeft: 9,
    },
}))

export const IconText = styled('span')(({ theme }) => ({
    fontWeight: 400,
    lineHeight: 1.25,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    marginBottom: 10,

    '& svg': {
        color: theme.palette.custom.mediumGrey.main,
        height: 15,
        width: 15,
        marginLeft: 9,
    },
}))

export const MyProfileContainer = styled(FlexRow)(({ theme }) => ({
    position: 'absolute',
    top: 12,
    right: 12,

    a: {
        textDecoration: 'none',
    },
}))

export const MyProfileAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    width: 46,
    height: 46,
    gap: 15,

    span: {
        display: 'none',
        fontSize: 16,
    },

    '&:hover': {
        width: 'fit-content',
        padding: '0 14px',

        svg: {
            color: theme.palette.custom.mustard.main,
        },

        span: {
            display: 'flex',
        },
    },
}))
