import { PersonAddRounded, PersonRemoveRounded, SearchRounded } from '@mui/icons-material'
import { Button, Checkbox, InputAdornment, TableSortLabel } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Spacer } from '../styles/spacing'
import {
    StandardTable,
    StandardTableBody,
    StandardTableCell,
    StandardTableCellCheckbox,
    StandardTableContainer,
    StandardTableHead,
    StandardTableRow,
    StandardTableToolbar,
} from '../styles/table'
import StandardTextField from '../styles/textfield'
import { ConfirmMultipleDeactivationsDialog } from '../utils/dialogs'
import { editionHasMultipleSeats, getName, userTypeToString } from '../utils/utils'
import BulkActions from './bulk-actions'
import Dialog from './dialog'
import StandardButton from '../styles/button'
import AddUserDialog from './add-user-dialog'

type DialogType = 'activation' | 'addUser'

const UsersTable = ({
    users,
    active,
    handleManage,
    handleActivationToggle,
    handleUpdate,
    handleAddUser,
}: UsersTableProps) => {
    const [selected, setSelected] = useState<string[]>([])
    const [searchBy, setSearchBy] = useState<string>('')
    const [searchByDelayed, setSearchByDelayed] = useState<string>('')
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [dialogType, setDialogType] = useState<DialogType>('activation')

    useEffect(() => {
        const timer = setTimeout(() => setSearchBy(searchByDelayed), 500)
        return (): void => clearTimeout(timer)
    }, [searchByDelayed])

    const columns: ColumnData[] = useMemo((): ColumnData[] => {
        const cols: ColumnData[] = [
            { id: 'user', name: 'User' },
            { id: 'email', name: 'Email' },
            { id: 'role', name: 'Role' },
            { id: 'editions', name: 'Editions' },
        ]
        if (!active) {
            cols.pop()
        }
        return cols
    }, [active])

    const [sorting, setSorting] = useState<TableSorting>({
        column: columns[0].id,
        direction: 'desc',
    })

    const sortRows = useCallback(
        (a: RowData, b: RowData): number => {
            for (let i: number = 0; i < columns.length; i++) {
                if (sorting.column === columns[i].id) {
                    if (a.cells[i] === b.cells[i]) {
                        return 0
                    } else {
                        const direction = sorting.direction === 'asc' ? 1 : -1
                        return a.cells[i] < b.cells[i] ? direction : -direction
                    }
                }
            }
            return -1
        },
        [sorting, columns]
    )

    const editionName = (seatAssigments: SeatAssignment[], line: SubscriptionLine): string => {
        return editionHasMultipleSeats(seatAssigments).includes(line.product.id)
            ? `${line.product.name} (${line.name})`
            : line.product.name
    }

    const rows: RowData[] = useMemo((): RowData[] => {
        const rowsData: RowData[] = users?.map((user: UserInfo): RowData => {
            const cells: string[] = [
                getName(user.profile) || '',
                user.profile.email,
                userTypeToString(user.profile),
                user.seatassigmentsList
                    .map((seat: SeatAssignment): string => {
                        if (seat.numberofseats > 1) {
                            return `${editionName(user.seatassigmentsList, seat.subscriptionline)} (x${
                                seat.numberofseats
                            })`
                        } else {
                            return editionName(user.seatassigmentsList, seat.subscriptionline)
                        }
                    })
                    .join(', '),
            ]
            if (!active) {
                cells.pop()
            }
            return {
                id: user.profile.userid,
                cells,
            }
        })
        return ([...rowsData] as RowData[])
            .sort((a: RowData, b: RowData): number => sortRows(a, b))
            .filter((row: RowData): boolean => {
                if (searchBy) {
                    const matches: boolean[] = row.cells
                        .map(
                            (cell: string | number): boolean =>
                                String(cell).toLowerCase().indexOf(searchBy.toLowerCase()) === -1
                        )
                        .filter((result: boolean): boolean => !result)
                    return !!matches.length
                }
                return true
            })
    }, [users, searchBy, active, sortRows])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchByDelayed(event.target.value)
    }

    const isColumnActive = useCallback(
        (id: string): boolean | undefined => {
            return sorting.column === id
        },
        [sorting]
    )

    const handleChangeSorting = useCallback(
        (event: React.PointerEvent<HTMLSpanElement>) => {
            const id: string = (event.target as HTMLSpanElement).id
            if (isColumnActive(id)) {
                if (sorting.direction === 'asc') {
                    setSorting({ ...sorting, direction: 'desc' })
                } else {
                    setSorting({ ...sorting, direction: 'asc' })
                }
            } else {
                setSorting({ column: id, direction: 'desc' })
            }
        },
        [sorting, isColumnActive]
    )

    const getColumnDirection = useCallback(
        (id: string): TableSortingDirection => {
            if (isColumnActive(id)) {
                return sorting.direction
            }
            return 'desc'
        },
        [sorting, isColumnActive]
    )

    const areAllItemSelected = useCallback((): boolean | undefined => {
        return selected.length === rows.length
    }, [selected, rows])

    const areSomeItemSelected = useCallback((): boolean | undefined => {
        return rows.length > 0 && selected.length > 0 && selected.length !== rows.length
    }, [selected, rows])

    const isItemSelected = useCallback(
        (id: string): boolean | undefined => {
            return !!selected.find((item: string) => item === id)
        },
        [selected]
    )

    const onSelectAllClick = useCallback((): void => {
        selected.length ? setSelected([]) : setSelected([...rows.map(row => row.id)])
    }, [selected, rows])

    const onSelectClick = useCallback(
        (id: string): void => {
            if (isItemSelected(id)) {
                setSelected([...selected.filter(item => item !== id)])
            } else {
                setSelected([...selected, id])
            }
        },
        [selected, isItemSelected]
    )

    const getBulkActions = (active: boolean): BulkActionOption[] => {
        if (active) {
            return [
                {
                    id: 'deactivate',
                    label: 'Deactivate',
                    icon: PersonRemoveRounded,
                    color: 'error',
                },
            ]
        } else {
            return [
                {
                    id: 'activate',
                    label: 'Activate',
                    icon: PersonAddRounded,
                    color: 'primary',
                },
            ]
        }
    }

    const handleBulkAction = (option: BulkActionOption) => {
        if (option.id === 'activate') {
            handleBulkActivationToggle(true)
        } else if (option.id === 'deactivate') {
            setDialogType('activation')
            setShowDialog(true)
        }
    }

    const handleBulkActivationToggle = useCallback(
        (activate: boolean) => {
            Promise.allSettled(selected.map(userId => handleActivationToggle(activate, userId))).then(promises => {
                setSelected([])
                handleUpdate()
            })
        },
        [handleActivationToggle, handleUpdate, selected]
    )

    const handleActivation = (userId: string) => {
        Promise.allSettled([handleActivationToggle(true, userId)]).then(promises => {
            setSelected([...selected.filter(item => item !== userId)])
            handleUpdate()
        })
    }

    const handleConfirm = useCallback(() => {
        handleBulkActivationToggle(false)
        setShowDialog(false)
    }, [handleBulkActivationToggle])

    const handleAddUserForm = () => {
        setDialogType('addUser')
        setShowDialog(true)
    }

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ConfirmMultipleDeactivationsDialog,
            open: showDialog && dialogType === 'activation',
            handleOpen: setShowDialog,
            handleConfirm,
        }
    }, [showDialog, dialogType, handleConfirm])

    const addUserDialogProps: AddUserModalDialogProps = useMemo((): AddUserModalDialogProps => {
        return {
            ...AddUserDialog,
            open: showDialog && dialogType === 'addUser',
            handleOpen: setShowDialog,
            handleAddUser: handleAddUser,
        }
    }, [showDialog, dialogType, handleAddUser])

    return (
        <StandardTableContainer>
            <StandardTableToolbar>
                <StandardTextField
                    id='users-search'
                    placeholder='Search...'
                    type='search'
                    variant='standard'
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                />
                <Spacer />
                <BulkActions
                    label='Bulk actions'
                    options={getBulkActions(active)}
                    disabled={!selected.length}
                    handleOptionClick={handleBulkAction}
                />
                {handleAddUser && (
                    <StandardButton onClick={handleAddUserForm} disabled={!handleAddUser}>
                        Add User
                    </StandardButton>
                )}
            </StandardTableToolbar>

            {!!rows.length && (
                <StandardTable>
                    <StandardTableHead>
                        <StandardTableRow>
                            <StandardTableCellCheckbox>
                                <Checkbox
                                    color='primary'
                                    indeterminate={areSomeItemSelected()}
                                    checked={areAllItemSelected()}
                                    onChange={onSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all users',
                                    }}
                                />
                            </StandardTableCellCheckbox>
                            {columns.map(column => (
                                <StandardTableCell key={column.id}>
                                    <TableSortLabel
                                        active={isColumnActive(column.id)}
                                        direction={getColumnDirection(column.id)}
                                        id={column.id}
                                        onClick={handleChangeSorting}>
                                        {column.name}
                                    </TableSortLabel>
                                </StandardTableCell>
                            ))}
                        </StandardTableRow>
                    </StandardTableHead>
                    <StandardTableBody>
                        {rows?.map(row => (
                            <StandardTableRow key={row.id}>
                                <StandardTableCellCheckbox padding='checkbox'>
                                    <Checkbox
                                        color='primary'
                                        checked={isItemSelected(row.id)}
                                        onChange={() => onSelectClick(row.id)}
                                        inputProps={{
                                            'aria-labelledby': row.id,
                                        }}
                                    />
                                </StandardTableCellCheckbox>
                                {row.cells.map((cell, i) => (
                                    <StandardTableCell key={`${row.id}-${columns[i].id}`}>{cell}</StandardTableCell>
                                ))}
                                {active && (
                                    <>
                                        <StandardTableCell>
                                            <Button onClick={() => handleManage!(row.id)}>Manage</Button>
                                        </StandardTableCell>
                                    </>
                                )}
                                {!active && (
                                    <StandardTableCell>
                                        <Button onClick={() => handleActivation(row.id)}>Activate</Button>
                                    </StandardTableCell>
                                )}
                            </StandardTableRow>
                        ))}
                    </StandardTableBody>
                </StandardTable>
            )}
            <Dialog {...dialogProps} />
            {showDialog && <AddUserDialog {...addUserDialogProps} />}
        </StandardTableContainer>
    )
}

export default UsersTable
