import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callBilling, callUpdateBilling } from '../grpc/api'

const initialState: BillingState = {
    billing: {
        email: '',
        taxid: '',
        documentsList: [],
        address: {
            city: '',
            country: '',
            postalcode: '',
            province: '',
            street: '',
            countrycode: '',
        },
    },
    billingModified: {
        email: '',
        taxid: '',
        documentsList: [],
        address: {
            city: '',
            country: '',
            postalcode: '',
            province: '',
            street: '',
            countrycode: '',
        },
    },
    error: null,
    status: 'not_loaded',
}

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        setBilling: (state: BillingState, action: PayloadAction<Billing>): void => {
            state.billing = action.payload
        },
        setBillingModified: (state: BillingState, action: PayloadAction<Billing>): void => {
            state.billingModified = action.payload
        },
        resetBilling: (state: BillingState): void => {
            state.billingModified = state.billing
            state.status = 'loaded'
            state.error = null
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchBilling.pending, (state: BillingState): void => {
                state.status = 'loading'
            })
            .addCase(fetchBilling.fulfilled, (state: BillingState, action: PayloadAction<Billing>): void => {
                state.status = 'loaded'
                state.billing = action.payload
                state.billingModified = action.payload
                state.error = null
            })
            .addCase(fetchBilling.rejected, (state: BillingState, action): void => {
                state.status = 'failed'
                state.error = action.error
            })
            .addCase(updateBilling.pending, (state: BillingState): void => {
                state.status = 'loading'
            })
            .addCase(updateBilling.fulfilled, (state: BillingState): void => {
                state.status = 'success'
                state.billing = state.billingModified
                state.error = null
            })
            .addCase(updateBilling.rejected, (state: BillingState, action): void => {
                state.status = 'failed'
                state.error = action.error
            })
    },
})

export const fetchBilling = createAsyncThunk('billing/fetchBilling', async (): Promise<Billing> => {
    return await callBilling()
})

export const updateBilling = createAsyncThunk(
    'billing/updateBilling',
    async (billing: Billing): Promise<EmptyResponse> => {
        return await callUpdateBilling(billing)
    }
)

export const { setBilling, setBillingModified, resetBilling } = billingSlice.actions

export default billingSlice.reducer
