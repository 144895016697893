import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callAssignSeat, callUnassignSeat, callUserSeatAssignments } from '../grpc/api'

const initialState: SeatAssignmentsState = {
    seatAssignments: [],
    error: null,
    assignError: null,
    status: 'not_loaded',
}

export const seatAssignmentsSlice = createSlice({
    name: 'seatAssignments',
    initialState,
    reducers: {
        setSeatAssignments: (state: SeatAssignmentsState, { payload }: PayloadAction<SeatAssignment[]>): void => {
            state.seatAssignments = payload
        },
        resetSeatAssignments: (state: SeatAssignmentsState): void => {
            state.seatAssignments = initialState.seatAssignments
            state.status = initialState.status
            state.error = initialState.error
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchSeatAssignments.pending, (state: SeatAssignmentsState): void => {
                state.status = 'loading'
            })
            .addCase(
                fetchSeatAssignments.fulfilled,
                (state: SeatAssignmentsState, { payload }: PayloadAction<UserSeatAssignmentsResponse>): void => {
                    state.status = 'loaded'
                    state.seatAssignments = payload.seatsList
                    state.error = null
                }
            )
            .addCase(fetchSeatAssignments.rejected, (state: SeatAssignmentsState, action): void => {
                state.status = 'failed'
                state.error = action.error
            })
            .addCase(assignSeat.pending, (state: SeatAssignmentsState): void => {
                state.status = 'loading'
            })
            .addCase(assignSeat.fulfilled, (state: SeatAssignmentsState): void => {
                state.status = 'loaded'
                state.assignError = null
            })
            .addCase(assignSeat.rejected, (state: SeatAssignmentsState, action): void => {
                state.status = 'failed'
                state.assignError = action.error
            })
            .addCase(unassignSeat.pending, (state: SeatAssignmentsState): void => {
                state.status = 'loading'
            })
            .addCase(unassignSeat.fulfilled, (state: SeatAssignmentsState): void => {
                state.status = 'loaded'
                state.assignError = null
            })
            .addCase(unassignSeat.rejected, (state: SeatAssignmentsState, action): void => {
                state.status = 'failed'
                state.assignError = action.error
            })
    },
})

export const fetchSeatAssignments = createAsyncThunk(
    'seatAssignments/seatAssignments',
    async (userId: string): Promise<UserSeatAssignmentsResponse> => {
        return await callUserSeatAssignments(userId)
    }
)

export const assignSeat = createAsyncThunk(
    'seatAssignments/assignSeat',
    async ({
        userId,
        subscriptionLineId,
        numberOfSeats,
    }: {
        userId: string
        subscriptionLineId: string
        numberOfSeats: number
    }): Promise<SeatAssignment> => {
        return await callAssignSeat(userId, subscriptionLineId, numberOfSeats)
    }
)

export const unassignSeat = createAsyncThunk(
    'seatAssignments/unassignSeat',
    async ({ userId, seatId }: { userId: string; seatId: string }): Promise<EmptyResponse> => {
        return await callUnassignSeat(userId, seatId)
    }
)

export const { setSeatAssignments, resetSeatAssignments } = seatAssignmentsSlice.actions

export default seatAssignmentsSlice.reducer
