import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callChangeUserType, callUpdateProfile, callUser } from '../grpc/api'

const initialState: UserState = {
    profile: {
        userid: '',
        cognitoid: '',
        email: '',
        firstname: '',
        lastname: '',
        salutation: '',
        usertype: 0,
        address: {
            city: '',
            country: '',
            postalcode: '',
            province: '',
            street: '',
            countrycode: '',
        },
    },
    profileModified: {
        userid: '',
        cognitoid: '',
        email: '',
        firstname: '',
        lastname: '',
        salutation: '',
        usertype: 0,
        address: {
            city: '',
            country: '',
            postalcode: '',
            province: '',
            street: '',
            countrycode: '',
        },
    },
    active: true,
    error: null,
    status: 'not_loaded',
    userTypeStatus: 'not_loaded',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setProfile: (state: UserState, action: PayloadAction<Profile>): void => {
            state.profile = action.payload
        },
        setProfileModified: (state: UserState, action: PayloadAction<Profile>): void => {
            state.profileModified = action.payload
        },
        setProfileActive: (state: UserState, action: PayloadAction<boolean>): void => {
            state.active = action.payload
        },
        resetProfile: (state: UserState): void => {
            state.profile = initialState.profile
            state.profileModified = initialState.profileModified
            state.active = initialState.active
            state.status = initialState.status
            state.userTypeStatus = initialState.userTypeStatus
            state.error = initialState.error
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchUser.pending, (state: UserState): void => {
                state.status = 'loading'
            })
            .addCase(fetchUser.fulfilled, (state: UserState, action): void => {
                state.status = 'loaded'
                state.profile = action.payload.profile
                state.profileModified = action.payload.profile
                state.error = null
            })
            .addCase(fetchUser.rejected, (state: UserState, action): void => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateProfile.pending, (state: UserState): void => {
                state.status = 'loading'
            })
            .addCase(updateProfile.fulfilled, (state: UserState): void => {
                state.status = 'loaded'
                state.profile = state.profileModified
                state.error = null
            })
            .addCase(updateProfile.rejected, (state: UserState, action): void => {
                state.status = 'failed'
                state.error = action.error
            })
            .addCase(changeUserType.pending, (state: UserState): void => {
                state.userTypeStatus = 'loading'
            })
            .addCase(changeUserType.fulfilled, (state: UserState): void => {
                state.userTypeStatus = 'loaded'
                state.error = null
            })
            .addCase(changeUserType.rejected, (state: UserState, action): void => {
                state.userTypeStatus = 'failed'
                state.error = action.error
            })
    },
})

export const fetchUser = createAsyncThunk('user/fetchUser', async (userId: string): Promise<UserInfo> => {
    return await callUser(userId)
})

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (profile: Profile): Promise<EmptyResponse> => {
        return await callUpdateProfile(profile)
    }
)

export const changeUserType = createAsyncThunk(
    'profile/changeUserType',
    async ({ userId, userType }: { userId: string; userType: number }): Promise<EmptyResponse> => {
        return await callChangeUserType(userId, userType)
    }
)

export const { setProfile, setProfileModified, setProfileActive, resetProfile } = userSlice.actions

export default userSlice.reducer
