import { useCallback, useState } from 'react'
import TabPanel from '../components/tab-panels/tab-panel'
import { Page } from '../styles/page'
import { BigTab, BigTabs } from '../styles/tabs'
import BillingAccount from '../components/billing-account'
import BillingDocuments from '../components/billing-documents'
import { Header, HeaderDivider } from '../styles/header'
import { Spacing } from '../styles/spacing'

const Billing = (): React.ReactElement => {
    const [selectedTab, setSelectedTab] = useState<number>(0)

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    return (
        <Page>
            <Header>Billing</Header>
            <HeaderDivider />

            <BigTabs value={selectedTab} onChange={handleChange} aria-label='manage account tabs'>
                <BigTab label='Account overview' {...a11yProps(0)} />
                <BigTab label='Billing history' {...a11yProps(1)} />
                <BigTab label='Active quotes' {...a11yProps(2)} />
            </BigTabs>

            <Spacing />

            <TabPanel value={selectedTab} index={0}>
                <BillingAccount />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <BillingDocuments type={2} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <BillingDocuments type={1} />
            </TabPanel>
        </Page>
    )
}

export default Billing
