import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Root from './containers/root'
import Subscriptions from './containers/subscriptions'
import Users from './containers/users'
import Billing from './containers/billing'
import RootError from './containers/root-error'
import Error from './components/error'

const Routes = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <RootError />,
            children: [
                {
                    index: true,
                    element: <Navigate to='users' />,
                },
                {
                    path: 'users',
                    element: <Users />,
                    errorElement: <Error />,
                },
                {
                    path: 'subscriptions',
                    element: <Subscriptions />,
                    errorElement: <Error />,
                },
                {
                    path: 'billing',
                    element: <Billing />,
                    errorElement: <Error />,
                },
            ],
        },
    ])

    return <RouterProvider router={router} />
}

export default Routes
