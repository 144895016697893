import { styled } from '@mui/material'
import { FlexColumn, FlexColumnCenter, FlexRow } from './flex'
import { Header, Subheader } from './header'

export const UserPanelContainer = styled('div')(({ theme }) => ({
    minWidth: 425,
    padding: 40,
}))

export const ManagePanelContainer = styled(FlexColumnCenter)(({ theme }) => ({
    paddingTop: 60,
}))

export const ManagePanelAccount = styled(FlexColumn)(({ theme }) => ({
    width: 200,
    gap: 10,
}))

export const ClosePanel = styled(FlexRow)(({ theme }) => ({
    justifyContent: 'flex-end',
    // paddingBottom: 169,
    paddingBottom: 89,
}))

export const Buttons = styled(FlexRow)(({ theme }) => ({
    gap: 20,
}))

export const EditionColumn = styled(FlexColumn)({
    lineHeight: 1,
})

export const EditionTitle = styled(Header)({
    fontSize: 16,
    fontWeight: 600,
})

export const EditionAvailableSeats = styled(Subheader)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
}))

export const EditionAssignedSeats = styled(EditionAvailableSeats)(({ theme }) => ({
    color: theme.palette.success.main,
}))
