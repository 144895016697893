import { styled, Select } from '@mui/material'

const StandardSelect = styled(Select)(({ theme }) => ({}))

export const PlainSelect = styled(Select)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.primary.main,
    border: 'none',
    maxWidth: 350,

    '.MuiInputLabel-root': {
        color: theme.palette.custom.darkGrey.main,
    },

    '::before': {
        border: 'none',
        borderColor: `${theme.palette.custom.lightGrey.main} !important`,
    },

    ':hover': {
        border: 'none',
    },

    '.MuiSelect-select:focus': {
        backgroundColor: 'transparent',
    },

    '::after': {
        borderColor: theme.palette.custom.mediumGrey.main,
    },

    '&.Mui-disabled': {
        color: 'red',

        '&:before': {
            borderBottomStyle: 'hidden !important',
        },

        '.MuiSelect-select': {
            color: theme.palette.custom.darkGrey.main,
            WebkitTextFillColor: theme.palette.custom.darkGrey.main,
        },
    },
}))

export default StandardSelect
