import { useMemo } from 'react'
import { BuildNumber } from '../styles/build'
const { version, commit, date } = require('../assets/version.json')

const BuildVersion: React.FC = () => {
    const buildNumber = useMemo((): string => {
        return `v${version} (${commit} ${date})`
    }, [])

    return <BuildNumber>{buildNumber}</BuildNumber>
}

export default BuildVersion
