import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    callAccountProfile,
    callActivateUser,
    callAddUser,
    callDeactivateUser,
    callInactiveUsers,
    callUsers,
} from '../grpc/api'

const initialState: UsersState = {
    users: {
        list: [],
        status: 'not_loaded',
        error: null,
    },
    inactiveUsers: {
        list: [],
        status: 'not_loaded',
        error: null,
    },
    accountProfile: {
        userId: '',
        domains: [],
        status: 'not_loaded',
        error: null,
        newUserStatus: 'not_loaded',
        newUserError: null,
    },
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state: UsersState, action: PayloadAction<UserInfo[]>): void => {
            state.users.list = action.payload
        },
        setInactiveUsers: (state: UsersState, action: PayloadAction<UserInfo[]>): void => {
            state.inactiveUsers.list = action.payload
        },
        resetNewUser: (state: UsersState): void => {
            state.accountProfile.newUserStatus = 'not_loaded'
            state.accountProfile.newUserError = null
        },
    },
    extraReducers(builder): void {
        builder
            .addCase(fetchUsers.pending, (state: UsersState): void => {
                state.users.status = 'loading'
            })
            .addCase(fetchUsers.fulfilled, (state: UsersState, action: PayloadAction<UserInfo[], string>): void => {
                state.users.status = 'loaded'
                state.users.list = action.payload
                state.users.error = null
            })
            .addCase(fetchUsers.rejected, (state: UsersState, action): void => {
                state.users.status = 'failed'
                state.users.error = action.error
            })
            .addCase(fetchInactiveUsers.pending, (state: UsersState): void => {
                state.inactiveUsers.status = 'loading'
            })
            .addCase(
                fetchInactiveUsers.fulfilled,
                (state: UsersState, action: PayloadAction<UserInfo[], string>): void => {
                    state.inactiveUsers.status = 'loaded'
                    state.inactiveUsers.list = action.payload
                    state.inactiveUsers.error = null
                }
            )
            .addCase(fetchInactiveUsers.rejected, (state: UsersState, action): void => {
                state.inactiveUsers.status = 'failed'
                state.inactiveUsers.error = action.error
            })
            .addCase(activateUser.pending, (state: UsersState): void => {
                state.users.status = 'loading'
            })
            .addCase(activateUser.fulfilled, (state: UsersState): void => {
                state.users.status = 'loaded'
                state.users.error = null
            })
            .addCase(activateUser.rejected, (state: UsersState, action): void => {
                state.users.status = 'failed'
                state.users.error = action.error
            })
            .addCase(deactivateUser.pending, (state: UsersState): void => {
                state.inactiveUsers.status = 'loading'
                state.inactiveUsers.error = null
            })
            .addCase(deactivateUser.fulfilled, (state: UsersState): void => {
                state.inactiveUsers.status = 'loaded'
                state.inactiveUsers.error = null
            })
            .addCase(deactivateUser.rejected, (state: UsersState, action): void => {
                state.inactiveUsers.status = 'failed'
                state.inactiveUsers.error = action.error
            })
            .addCase(fetchAccountProfile.pending, (state: UsersState): void => {
                state.accountProfile.status = 'loading'
            })
            .addCase(
                fetchAccountProfile.fulfilled,
                (state: UsersState, action: PayloadAction<AccountProfile, string>): void => {
                    state.accountProfile.status = 'loaded'
                    state.accountProfile.domains = action.payload.domainsList
                    state.accountProfile.error = null
                }
            )
            .addCase(fetchAccountProfile.rejected, (state: UsersState, action): void => {
                state.accountProfile.status = 'failed'
                state.accountProfile.error = action.error
            })
            .addCase(addUser.pending, (state: UsersState): void => {
                state.accountProfile.newUserStatus = 'loading'
                state.accountProfile.newUserError = null
            })
            .addCase(addUser.fulfilled, (state: UsersState, action: PayloadAction<UserId, string>): void => {
                state.accountProfile.newUserStatus = 'success'
                state.accountProfile.newUserError = null
                state.accountProfile.userId = action.payload.userId
            })
            .addCase(addUser.rejected, (state: UsersState, action): void => {
                state.accountProfile.newUserStatus = 'failed'
                state.accountProfile.newUserError = action.error
            })
    },
})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (): Promise<UserInfo[]> => {
    return (await callUsers()).userinfoList
})

export const fetchInactiveUsers = createAsyncThunk('users/fetchInactiveUsers', async (): Promise<UserInfo[]> => {
    return (await callInactiveUsers()).userinfoList
})

export const activateUser = createAsyncThunk('users/activateUser', async (userId: string): Promise<EmptyResponse> => {
    return await callActivateUser(userId)
})

export const deactivateUser = createAsyncThunk(
    'users/deactivateUser',
    async (userId: string): Promise<EmptyResponse> => {
        return await callDeactivateUser(userId)
    }
)

export const addUser = createAsyncThunk('users/addUser', async (addUserData: AddUserData): Promise<UserId> => {
    return await callAddUser(addUserData)
})

export const fetchAccountProfile = createAsyncThunk('users/accountProfile', async (): Promise<AccountProfile> => {
    return await callAccountProfile()
})

export const { setUsers, setInactiveUsers, resetNewUser } = usersSlice.actions

export default usersSlice.reducer
