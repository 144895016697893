import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const loadPersistedState = () => {
    const storedUuid = localStorage.getItem('uuid')
    const storedAccessToken = localStorage.getItem('access_token')
    const storedRefreshToken = localStorage.getItem('refresh_token')
    const storedIdToken = localStorage.getItem('id_token')
    let uuid, accessToken, refreshToken, idToken
    let status: AuthStatus = 'unauthenticated'
    if (storedUuid) {
        uuid = JSON.parse(storedUuid)
    } else {
        uuid = crypto.randomUUID()
    }
    if (storedAccessToken && storedAccessToken !== 'undefined') {
        accessToken = JSON.parse(storedAccessToken)
        status = 'authenticated'
    }
    if (storedRefreshToken && storedRefreshToken !== 'undefined') {
        refreshToken = JSON.parse(storedRefreshToken)
    }
    if (storedIdToken && storedIdToken !== 'undefined') {
        idToken = JSON.parse(storedIdToken)
    }
    return { uuid, accessToken, refreshToken, idToken, status }
}

const initialState: AuthState = loadPersistedState()

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUuid: (state, action: PayloadAction<string>) => {
            state.uuid = action.payload
        },
        setAuthTokens: (state, action: PayloadAction<AuthPayload>) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.idToken = action.payload.idToken
        },
        setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
            state.status = action.payload
        },
    },
})

export const { setUuid, setAuthTokens, setAuthStatus } = authSlice.actions

export default authSlice.reducer
