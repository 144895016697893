import { useRouteError } from 'react-router-dom'
import { Header, Subheader } from '../styles/header'
import { PageCentered } from '../styles/page'

const Error: React.FC = () => {
    const error: Error = useRouteError() as Error
    return (
        <PageCentered>
            <Header>Something went wrong</Header>
            <Subheader>{error.message || 'Unexpected error has occurred'}</Subheader>
        </PageCentered>
    )
}

export default Error
