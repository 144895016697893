import { styled } from '@mui/material'
import { FlexRow } from './flex'
import { Header, Subheader } from './header'

export const ProfileHeaderContainer = styled(FlexRow)({
    alignItems: 'center',
    gap: 12,
})

export const ProfileHeaderName = styled(Header)({})

export const ProfileHeaderEmail = styled(Subheader)(({ theme }) => ({
    color: theme.palette.text.primary,
}))
