import { useRouteError } from 'react-router-dom'
import SideBar from '../components/sidebar'
import { Header, Subheader } from '../styles/header'
import { MainContainer, PageCentered } from '../styles/page'
import ProfileLink from '../components/profile-link'
import { FlexRow } from '../styles/flex'

const RootError: React.FC = () => {
    const error: Error = useRouteError() as Error
    return (
        <FlexRow>
            <SideBar />
            <ProfileLink />
            <MainContainer>
                <PageCentered>
                    <Header>Something went wrong</Header>
                    <Subheader>{error.message || 'Unexpected error has occurred'}</Subheader>
                </PageCentered>
            </MainContainer>
        </FlexRow>
    )
}

export default RootError
