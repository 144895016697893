import { Person } from '@mui/icons-material'
import { useMemo } from 'react'
import { selectAccessToken, selectRefreshToken } from '../app/selectors'
import { useAppSelector } from '../app/store'
import { MyProfileAvatar, MyProfileContainer } from '../styles/profile'

const ProfileLink = () => {
    const accessToken: string = useAppSelector(selectAccessToken)
    const refreshToken: string = useAppSelector(selectRefreshToken)

    const myProfileUrl = useMemo((): string => {
        return `${process.env.REACT_APP_USER_URL}/profile/about?token=${accessToken}&refresh_token=${refreshToken}`
    }, [accessToken, refreshToken])

    return (
        <MyProfileContainer>
            <a href={myProfileUrl} target='_blank' rel='noreferrer'>
                <MyProfileAvatar>
                    <Person />
                    <span>Profile</span>
                </MyProfileAvatar>
            </a>
        </MyProfileContainer>
    )
}

export default ProfileLink
