import { styled } from '@mui/material'

export const Spacing = styled('div')({
    minHeight: 58,
})

export const SpacingM = styled('div')({
    minHeight: 47,
})

export const SpacingS = styled('div')({
    minHeight: 13,
})

export const Spacer = styled('div')({
    flexGrow: 1,
})
