export const parseJwt = (token: string): JwtContent => {
    return JSON.parse(window.atob(token.split('.')[1]).toString())
}

export const rightTypeToString = (type: number) => {
    if (type === 1) {
        return 'Student Edition'
    } else if (type === 2) {
        return 'Postgraduate Edition'
    } else if (type === 3) {
        return 'Viewer Edition'
    } else if (type === 4) {
        return 'Staff'
    } else if (type === 5) {
        return 'Subscription'
    } else {
        return 'Unknown'
    }
}

export const fileTypeToString = (type: number) => {
    if (type === 0) {
        return 'Generic'
    } else if (type === 1) {
        return 'Quote'
    } else if (type === 2) {
        return 'Invoice'
    } else {
        return 'Unknown'
    }
}

export const userTypeToString = (profile: Profile) => {
    if (profile.usertype === 1) {
        return 'Admin'
    } else if (profile.usertype === 2) {
        return 'Billing'
    }
    return 'User'
}

export const getName = (
    profile: Profile | null,
    ignoreSalutation: boolean = process.env.REACT_APP_IGNORE_SALUTATION === 'true'
) => {
    if (profile) {
        if (ignoreSalutation) {
            return `${profile.firstname} ${profile.lastname}`.trim()
        } else {
            return `${profile.salutation} ${profile.firstname} ${profile.lastname}`.trim()
        }
    }
    return null
}

export const editionHasMultipleSubscriptions = (subscriptions: Subscription[]): string[] => {
    const multipleEditions: string[] = []
    const editions: string[] = []
    subscriptions.forEach((sub: Subscription): void => {
        sub.linesList.forEach((line: SubscriptionLine): void => {
            if (editions.includes(line.product.id)) {
                if (!multipleEditions.includes(line.product.id)) {
                    multipleEditions.push(line.product.id)
                }
            } else {
                editions.push(line.product.id)
            }
        })
    })
    return multipleEditions
}

export const editionHasMultipleSeats = (seats: SeatAssignment[]): string[] => {
    const multipleEditions: string[] = []
    const editions: string[] = []
    seats.forEach((seat: SeatAssignment): void => {
        if (editions.includes(seat.subscriptionline.product.id)) {
            if (!multipleEditions.includes(seat.subscriptionline.product.id)) {
                multipleEditions.push(seat.subscriptionline.product.id)
            }
        } else {
            editions.push(seat.subscriptionline.product.id)
        }
    })
    return multipleEditions
}
