import {
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    ListAltRounded,
    LogoutOutlined,
    PeopleOutlineSharp,
    ReceiptLongRounded,
} from '@mui/icons-material'
import { useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Sidebar, SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarToggle } from '../styles/sidebar'
import logo from '../assets/logo.svg'
import { signOut } from '../grpc/api'
import Dialog from './dialog'
import { ConfirmSignOutDialog } from '../utils/dialogs'
import BuildVersion from './build-version'

type classNameProps = { isActive: boolean; isPending: boolean }

const SideBar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(true)
    const [showDialog, setShowDialog] = useState<boolean>(false)

    const activeClassname = useCallback(({ isActive }: classNameProps) => (isActive ? 'active' : undefined), [])

    const handleToggle = useCallback(() => {
        setOpen(!open)
    }, [open])

    const handleConfirm = useCallback(() => {
        signOut()
        setShowDialog(false)
    }, [])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ConfirmSignOutDialog,
            open: showDialog,
            handleOpen: setShowDialog,
            handleConfirm,
        }
    }, [handleConfirm, showDialog])

    return (
        <Sidebar className={open ? 'open' : 'closed'}>
            <SidebarHeader>{open && <img src={logo} alt='myAimsun logo' className='logo' />}</SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem>
                    <NavLink to='users' className={activeClassname}>
                        <PeopleOutlineSharp />
                        {open && <span>Users</span>}
                    </NavLink>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <NavLink to='subscriptions' className={activeClassname}>
                        <ListAltRounded />
                        {open && <span>Subscriptions</span>}
                    </NavLink>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <NavLink to='billing' className={activeClassname}>
                        <ReceiptLongRounded />
                        {open && <span>Billing</span>}
                    </NavLink>
                </SidebarMenuItem>
            </SidebarMenu>
            <SidebarMenuItem onClick={() => setShowDialog(true)}>
                <span>
                    <LogoutOutlined />
                    {open && <span>Sign out</span>}
                </span>
            </SidebarMenuItem>
            <SidebarToggle onClick={handleToggle}>
                {!open ? <KeyboardArrowRightRounded /> : <KeyboardArrowLeftRounded />}
                {open && <span>Show less</span>}
            </SidebarToggle>
            {open && <BuildVersion />}
            <Dialog {...dialogProps} />
        </Sidebar>
    )
}

export default SideBar
