export const formatLocaleDate = (dateIso: string) => {
    return new Date(dateIso).toLocaleDateString()
}

export const formatLocaleTime = (dateIso: string) => {
    return new Date(dateIso).toLocaleTimeString()
}

export const formatLocaleDateTime = (dateIso: string) => {
    return new Date(dateIso).toLocaleString()
}
