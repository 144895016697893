import { ThemeProvider } from '@mui/material'
import aimsunLiveTheme from './assets/aimsun-live-theme'
import './assets/app.scss'
import Routes from './Routes'

function App() {
    return (
        <ThemeProvider theme={aimsunLiveTheme}>
            <Routes />
        </ThemeProvider>
    )
}

export default App
