export const GenericErrorDialog: ModalDialogBaseProps = {
    dialogType: 'alert',
    title: 'Connection error',
    message: 'The connection with the server failed',
}

export const CannotUpdateErrorDialog: ModalDialogBaseProps = {
    dialogType: 'alert',
    title: 'Cannot update',
    message: 'The changes cannot be saved',
}

export const InvalidTokenErrorDialog: ModalDialogBaseProps = {
    dialogType: 'alert',
    title: 'Invalid Token',
    message: 'The provided credentials are invalid',
}

export const OutdatedVersionDialog: ModalDialogBaseProps = {
    dialogType: 'permanent',
    title: 'Outdated version',
    message: 'You are unable to use the application because its outdated',
}

export const UnableToAuthenticateDialog: ModalDialogBaseProps = {
    dialogType: 'sign-out',
    title: 'Session expired',
    message: 'You need to sign in again',
}

export const ConfirmDeactivationDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'De-activate user account',
    message: 'Are you sure you want to de-activate the selected user account?',
}

export const ConfirmMultipleDeactivationsDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'De-activate users accounts',
    message: 'Are you sure you want to de-activate all selected users accounts?',
}

export const ConfirmSignOutDialog: ModalDialogBaseProps = {
    dialogType: 'simple',
    title: 'Sign out',
    message: 'Are you sure you want to sign out of myAimsun?',
}

export const ConfirmSeatAssignmentCountDialog: ModalDialogBaseProps = {
    dialogType: 'seat-number',
    title: 'Number of seats to assign',
    message: '',
    defaultValue: '1',
}
