import { AlertColor, Button, CircularProgress, Skeleton } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchSubscriptions } from '../../features/subscriptionsSlice'
import {
    StandardTable,
    StandardTableBody,
    StandardTableCell,
    StandardTableHead,
    StandardTableRow,
} from '../../styles/table'
import {
    EditionAssignedSeats,
    EditionAvailableSeats,
    EditionColumn,
    EditionTitle,
    ManagePanelContainer,
} from '../../styles/user-panel'
import { formatLocaleDate } from '../../utils/date'
import {
    selectSeatAssignError,
    selectSeatAssignments,
    selectSeatAssignmentsStatus,
    selectSubscriptions,
    selectSubscriptionsStatus,
    selectUserProfile,
} from '../../app/selectors'
import { useAppDispatch, useAppSelector } from '../../app/store'
import { assignSeat, unassignSeat, fetchSeatAssignments } from '../../features/seatAssignmentsSlice'
import { StandardAlert } from '../../styles/alert'
import StandardSnackbar from '../../styles/snackbar'
import { ConfirmSeatAssignmentCountDialog } from '../../utils/dialogs'
import Dialog from '../dialog'
import { CheckCircle } from '@mui/icons-material'
import { editionHasMultipleSubscriptions } from '../../utils/utils'

const ManageProductsPanel = ({ handleChanged, handleClosing }: ManagePanelProps) => {
    const dispatch = useAppDispatch()
    const profile: Profile = useAppSelector(selectUserProfile)
    const subscriptions: Subscription[] = useAppSelector(selectSubscriptions)
    const subscriptionsStatus: LoadStatus = useAppSelector(selectSubscriptionsStatus)
    const seatAssignments: SeatAssignment[] = useAppSelector(selectSeatAssignments)
    const seatAssignmentsStatus: LoadStatus = useAppSelector(selectSeatAssignmentsStatus)
    const error = useAppSelector(selectSeatAssignError)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [line, setLine] = useState<SubscriptionLine | undefined>(undefined)
    const [max, setMax] = useState<number>(1)

    useEffect(() => {
        if (subscriptionsStatus === 'not_loaded') {
            dispatch(fetchSubscriptions())
        }
        if (seatAssignmentsStatus === 'not_loaded') {
            dispatch(fetchSeatAssignments(profile.userid))
        } else if (seatAssignmentsStatus === 'success' || seatAssignmentsStatus === 'failed') {
            setShowSnackbar(true)
        }
    }, [dispatch, subscriptionsStatus, seatAssignmentsStatus, profile])

    const updating: boolean = useMemo(
        (): boolean => seatAssignmentsStatus === 'loading' || seatAssignmentsStatus === 'failed',
        [seatAssignmentsStatus]
    )
    const subscriptionsLoaded: boolean = useMemo(
        (): boolean => subscriptionsStatus === 'loaded' || subscriptionsStatus === 'failed',
        [subscriptionsStatus]
    )

    const findSubscriptionLine = useCallback(
        (lineId: string): SubscriptionLine => {
            const initSsubscriptionLine: SubscriptionLine = {
                id: '',
                name: '',
                seats: 0,
                usedseats: 0,
                maxactivations: 0,
                detachduration: 0,
                product: {
                    id: '',
                    name: '',
                },
                optionalproductsList: [],
                expirationdate: '',
                licensetype: 0,
            }
            for (const subscription of subscriptions) {
                for (const line of subscription.linesList) {
                    if (line.id === lineId) {
                        return line
                    }
                }
            }
            return initSsubscriptionLine
        },
        [subscriptions]
    )

    const getSeatBySubscriptionLine = useCallback(
        (subscriptionLineId: string): SeatAssignment => {
            const subscriptionline: SubscriptionLine = findSubscriptionLine(subscriptionLineId)
            const initSeat: SeatAssignment = {
                subscriptionline,
                id: subscriptionline.id,
                maxActivations: subscriptionline.maxactivations,
                detachduration: subscriptionline.detachduration,
                fromdate: '',
                todate: '',
                expirationdate: '',
                usedactivationsList: [],
                state: 0,
                usertokenstatus: 0,
                licensetype: 0,
                numberofseats: 0,
            }
            const activeSeat: SeatAssignment | undefined = seatAssignments.find((seat: SeatAssignment) => {
                return seat.subscriptionline.id === subscriptionLineId && seat.state === 1
            })
            const anySeat: SeatAssignment | undefined = seatAssignments.find((seat: SeatAssignment) => {
                return seat.subscriptionline.id === subscriptionLineId
            })
            return activeSeat || anySeat || initSeat
        },
        [seatAssignments, findSubscriptionLine]
    )

    const isAssigned = useCallback(
        (lineId: string) => {
            const seat: SeatAssignment = getSeatBySubscriptionLine(lineId)
            return seat.state === 1
        },
        [getSeatBySubscriptionLine]
    )

    const toggleAssignment = useCallback(
        (line: SubscriptionLine, toggle: boolean) => {
            const assignment = new Promise(resolve => {
                if (toggle) {
                    const seats: number = line.licensetype === 1 ? getSeatBySubscriptionLine(line.id).numberofseats : 1
                    resolve(
                        dispatch(
                            assignSeat({ userId: profile.userid, subscriptionLineId: line.id, numberOfSeats: seats })
                        )
                    )
                } else {
                    const seatId: string = getSeatBySubscriptionLine(line.id).id
                    resolve(dispatch(unassignSeat({ userId: profile.userid, seatId })))
                }
            })
            assignment.then(async () => {
                await dispatch(fetchSeatAssignments(profile.userid))
                await dispatch(fetchSubscriptions())
                handleChanged(true)
            })
        },
        [dispatch, getSeatBySubscriptionLine, handleChanged, profile]
    )

    const handleConfirmNetworkLicense = useCallback(
        (numberOfSeats: string | undefined): void => {
            if (line) {
                const assignment = new Promise(resolve =>
                    resolve(
                        dispatch(
                            assignSeat({
                                userId: profile.userid,
                                subscriptionLineId: line.id,
                                numberOfSeats: Number(numberOfSeats),
                            })
                        )
                    )
                )
                assignment.then(async () => {
                    await dispatch(fetchSeatAssignments(profile.userid))
                    await dispatch(fetchSubscriptions())
                    handleChanged(true)
                })
            }
            setShowDialog(false)
        },
        [dispatch, line, profile, handleChanged]
    )

    const hasMultipleSubscriptions: string[] = useMemo((): string[] => {
        return editionHasMultipleSubscriptions(subscriptions)
    }, [subscriptions])

    const editionName = (line: SubscriptionLine): string => {
        return hasMultipleSubscriptions.includes(line.product.id)
            ? `${line.product.name} (${line.name})`
            : line.product.name
    }

    const lineSeatCounter = useCallback(
        (lineId: string): number => {
            const line: SeatAssignment = getSeatBySubscriptionLine(lineId)
            let seats: number = line.subscriptionline.seats
            let usedseats: number = line.subscriptionline.usedseats
            return seats - usedseats
        },
        [getSeatBySubscriptionLine]
    )

    const lineAvailableSeatsLabel = (lineId: string): string => {
        const line: SeatAssignment = getSeatBySubscriptionLine(lineId)
        let seats: number = line.subscriptionline.seats
        let available: number = lineSeatCounter(lineId)
        return `${available} of ${seats} seats available`
    }

    const lineSeatsAssignedLabel = (lineId: string): string | undefined => {
        const line: SeatAssignment = getSeatBySubscriptionLine(lineId)
        if (line.numberofseats) {
            return `${line.numberofseats} ${line.numberofseats > 1 ? 'seats' : 'seat'} assigned to this user`
        }
    }

    const handleConfirmToggleAssignment = useCallback(
        (line: SubscriptionLine, toggle: boolean): void => {
            if (toggle === true && line.licensetype === 1) {
                setShowDialog(true)
                setLine(line)
                setMax(lineSeatCounter(line.id))
            } else {
                toggleAssignment(line, toggle)
            }
        },
        [toggleAssignment, lineSeatCounter]
    )

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return 'Subscriptions updated successfully'
    }, [error])

    const alertSeverity = useMemo((): AlertColor => {
        return error ? 'error' : 'success'
    }, [error])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ConfirmSeatAssignmentCountDialog,
            open: showDialog,
            handleOpen: setShowDialog,
            handleConfirm: handleConfirmNetworkLicense,
            min: 1,
            max,
        }
    }, [handleConfirmNetworkLicense, showDialog, max])

    return (
        <ManagePanelContainer>
            {!subscriptionsLoaded && <CircularProgress color='inherit' />}
            {subscriptionsLoaded && (
                <>
                    <StandardTable>
                        <StandardTableHead>
                            <StandardTableRow>
                                <StandardTableCell className='compact' />
                                <StandardTableCell className='compact'>Edition</StandardTableCell>
                                <StandardTableCell className='compact'>Expiration</StandardTableCell>
                                <StandardTableCell className='compact' />
                            </StandardTableRow>
                        </StandardTableHead>

                        <StandardTableBody>
                            {!subscriptions.length && (
                                <StandardTableRow>
                                    <StandardTableCell colSpan={4}>No subscriptions to display</StandardTableCell>
                                </StandardTableRow>
                            )}
                            {subscriptions
                                .filter(sub => !sub.expired)
                                .map((sub: Subscription) =>
                                    sub.linesList.map((line: SubscriptionLine) => (
                                        <StandardTableRow key={line.id}>
                                            <StandardTableCell className='compact'>
                                                {isAssigned(line.id) && <CheckCircle color='success' />}
                                            </StandardTableCell>
                                            <StandardTableCell key={`${line.id}-edition`} className='compact'>
                                                <EditionColumn>
                                                    <EditionTitle>{editionName(line)}</EditionTitle>
                                                    {lineSeatsAssignedLabel(line.id) && (
                                                        <EditionAssignedSeats>
                                                            {lineSeatsAssignedLabel(line.id)}
                                                        </EditionAssignedSeats>
                                                    )}
                                                    {!updating && (
                                                        <EditionAvailableSeats>
                                                            {lineAvailableSeatsLabel(line.id)}
                                                        </EditionAvailableSeats>
                                                    )}
                                                    {updating && <Skeleton width={128} />}
                                                </EditionColumn>
                                            </StandardTableCell>
                                            <StandardTableCell key={`${line.id}-expiry`} className='compact'>
                                                {formatLocaleDate(line.expirationdate)}
                                            </StandardTableCell>
                                            <StandardTableCell key={`${line.id}-assign`} className='compact'>
                                                {!isAssigned(line.id) && (
                                                    <Button
                                                        onClick={() => handleConfirmToggleAssignment(line, true)}
                                                        disabled={lineSeatCounter(line.id) === 0}>
                                                        Assign
                                                    </Button>
                                                )}
                                                {isAssigned(line.id) && (
                                                    <Button onClick={() => handleConfirmToggleAssignment(line, false)}>
                                                        Unassign
                                                    </Button>
                                                )}
                                            </StandardTableCell>
                                        </StandardTableRow>
                                    ))
                                )}
                        </StandardTableBody>
                    </StandardTable>
                </>
            )}
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
            <Dialog {...dialogProps} />
        </ManagePanelContainer>
    )
}

export default ManageProductsPanel
