import { RpcError } from 'grpc-web'
import { RootState } from './store'

export const selectLoading = (state: RootState): boolean =>
    state.users.users.status === 'loading' ||
    state.users.inactiveUsers.status === 'loading' ||
    state.subscriptions.status === 'loading'

export const selectAccessToken = (state: RootState) => state.auth.accessToken
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken
export const selectIdToken = (state: RootState) => state.auth.idToken
export const selectUuid = (state: RootState) => state.auth.uuid
export const selectAuthStatus = (state: RootState) => state.auth.status

export const selectApiVersion = (state: RootState): number => state.version.apiVersion
export const selectApiVersionStatus = (state: RootState): LoadStatus => state.version.status

export const selectSubscriptions = (state: RootState): Subscription[] => state.subscriptions.subscriptions
export const selectSubscriptionsStatus = (state: RootState): LoadStatus => state.subscriptions.status
export const selectSubscriptionsError = (state: RootState): RpcError => state.subscriptions.error

export const selectUsers = (state: RootState): UserInfo[] => state.users.users.list
export const selectUsersStatus = (state: RootState): LoadStatus => state.users.users.status
export const selectUsersError = (state: RootState): RpcError => state.users.users.error
export const selectInactiveUsers = (state: RootState): UserInfo[] => state.users.inactiveUsers.list
export const selectInactiveUsersStatus = (state: RootState): LoadStatus => state.users.inactiveUsers.status
export const selectInactiveUsersError = (state: RootState): RpcError => state.users.inactiveUsers.error
export const selectAccountProfileDomains = (state: RootState): string[] => state.users.accountProfile.domains
export const selectAccountProfileStatus = (state: RootState): LoadStatus => state.users.accountProfile.status
export const selectAccountProfileError = (state: RootState): RpcError => state.users.accountProfile.error
export const selectAccountProfileNewUserStatus = (state: RootState): LoadStatus =>
    state.users.accountProfile.newUserStatus
export const selectAccountProfileNewUserError = (state: RootState): RpcError => state.users.accountProfile.newUserError

export const selectUserProfile = (state: RootState): Profile => state.user.profile
export const selectUserProfileModified = (state: RootState): Profile => state.user.profileModified
export const selectUserActive = (state: RootState): boolean => state.user.active
export const selectUserStatus = (state: RootState): LoadStatus => state.user.status
export const selectUserError = (state: RootState): RpcError => state.user.error
export const selectUserTypeStatus = (state: RootState): LoadStatus => state.user.userTypeStatus

export const selectSeatAssignments = (state: RootState): SeatAssignment[] => state.seatAssignments.seatAssignments
export const selectSeatAssignmentsStatus = (state: RootState): LoadStatus => state.seatAssignments.status
export const selectSeatAssignmentsError = (state: RootState): RpcError => state.seatAssignments.error
export const selectSeatAssignError = (state: RootState): RpcError => state.seatAssignments.assignError

export const selectBilling = (state: RootState): Billing => state.billing.billing
export const selectBillingModified = (state: RootState): Billing => state.billing.billingModified
export const selectBillingStatus = (state: RootState): LoadStatus => state.billing.status
export const selectBillingError = (state: RootState): RpcError => state.billing.error

export const selectProfile = (state: RootState): Profile => state.profile.profile
export const selectProfileStatus = (state: RootState): LoadStatus => state.profile.status
export const selectProfileError = (state: RootState): RpcError => state.profile.error
