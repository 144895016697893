import { useSelector } from 'react-redux'
import { selectBillingStatus, selectBilling } from '../app/selectors'
import { useAppDispatch } from '../app/store'
import { CircularProgress, InputAdornment } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { fetchBilling } from '../features/billingSlice'
import { FlexColumn } from '../styles/flex'
import {
    StandardTable,
    StandardTableBody,
    StandardTableCell,
    StandardTableContainer,
    StandardTableHead,
    StandardTableRow,
    StandardTableToolbar,
} from '../styles/table'
import { LinkButtonAlt } from '../styles/button'
import { formatLocaleDate } from '../utils/date'
import { DownloadRounded, SearchRounded } from '@mui/icons-material'
import StandardTextField from '../styles/textfield'

const BillingDocuments = ({ type }: BillingDocumentsProps): React.ReactElement => {
    const dispatch = useAppDispatch()
    const billing: Billing = useSelector(selectBilling)
    const status: string = useSelector(selectBillingStatus)
    const [searchBy, setSearchBy] = useState<string>('')
    const [searchByDelayed, setSearchByDelayed] = useState<string>('')

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchBilling())
        }
    }, [dispatch, status])

    useEffect(() => {
        const timer = setTimeout(() => setSearchBy(searchByDelayed), 500)
        return () => clearTimeout(timer)
    }, [searchByDelayed])

    const loaded: boolean = useMemo((): boolean => status !== 'not_loaded' && status !== 'loading', [status])
    const documents: File[] = useMemo(
        (): File[] =>
            billing.documentsList
                .filter(doc => doc.type === type)
                .filter(row => {
                    if (searchBy) {
                        const matches: boolean[] = Object.keys(row)
                            .map(
                                (key: string) =>
                                    String(row[key as keyof File])
                                        .toLowerCase()
                                        .indexOf(searchBy.toLowerCase()) === -1
                            )
                            .filter((result: boolean) => !result)
                        return !!matches.length
                    }
                    return true
                }),
        [billing, type, searchBy]
    )
    const documentName: string = useMemo((): string => {
        if (type === 2) {
            return 'Invoice id'
        } else if (type === 1) {
            return 'Quote id'
        } else {
            return 'Document id'
        }
    }, [type])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchByDelayed(event.target.value)
    }

    return (
        <FlexColumn>
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <StandardTableContainer>
                    <StandardTableToolbar>
                        <StandardTextField
                            id='users-search'
                            placeholder='Search...'
                            type='search'
                            variant='standard'
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchRounded />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StandardTableToolbar>
                    {!!documents.length && (
                        <StandardTable>
                            <StandardTableHead>
                                <StandardTableRow>
                                    <StandardTableCell>{documentName}</StandardTableCell>
                                    <StandardTableCell>Date</StandardTableCell>
                                    <StandardTableCell>Format</StandardTableCell>
                                    <StandardTableCell />
                                </StandardTableRow>
                            </StandardTableHead>
                            <StandardTableBody>
                                {documents.map(doc => (
                                    <StandardTableRow key={doc.url}>
                                        <StandardTableCell>{doc.name}</StandardTableCell>
                                        <StandardTableCell>{formatLocaleDate(doc.date)}</StandardTableCell>
                                        <StandardTableCell>{doc.format}</StandardTableCell>
                                        <StandardTableCell>
                                            <LinkButtonAlt href={doc.url} target='_blank'>
                                                <DownloadRounded />
                                            </LinkButtonAlt>
                                        </StandardTableCell>
                                    </StandardTableRow>
                                ))}
                            </StandardTableBody>
                        </StandardTable>
                    )}
                </StandardTableContainer>
            )}
        </FlexColumn>
    )
}

export default BillingDocuments
