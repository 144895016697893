import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import SideBar from '../components/sidebar'
import { checkToken } from '../grpc/api'
import { fetchVersion } from '../features/versionSlice'
import { useAppDispatch, useAppSelector } from '../app/store'
import {
    selectAccessToken,
    selectApiVersionStatus,
    selectAuthStatus,
    selectIdToken,
    selectProfileStatus,
    selectRefreshToken,
    selectUuid,
} from '../app/selectors'
import { MainContainer } from '../styles/page'
import ProfileLink from '../components/profile-link'
import Dialog from '../components/dialog'
import { UnableToAuthenticateDialog } from '../utils/dialogs'
import { FlexRow } from '../styles/flex'
import { fetchProfile } from '../features/profileSlice'

const Root = (): React.ReactElement => {
    const dispatch = useAppDispatch()
    const status: string = useSelector(selectAuthStatus)
    const accessToken: string = useSelector(selectAccessToken)
    const refreshToken: string = useSelector(selectRefreshToken)
    const idToken: string = useSelector(selectIdToken)
    const uuid: string = useSelector(selectUuid)
    const apiVersionStatus: LoadStatus = useAppSelector(selectApiVersionStatus)
    const profileStatus: LoadStatus = useAppSelector(selectProfileStatus)

    useEffect((): void => {
        if (accessToken || refreshToken) {
            localStorage.setItem('uuid', JSON.stringify(uuid))
            localStorage.setItem('access_token', JSON.stringify(accessToken))
            localStorage.setItem('refresh_token', JSON.stringify(refreshToken))
            localStorage.setItem('id_token', JSON.stringify(idToken))
        }
    }, [uuid, accessToken, refreshToken, idToken])

    useEffect((): void => {
        checkToken()
        if (apiVersionStatus === 'not_loaded') {
            dispatch(fetchVersion())
        }
        if (profileStatus === 'not_loaded') {
            dispatch(fetchProfile())
        }
    }, [dispatch, apiVersionStatus, profileStatus])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...UnableToAuthenticateDialog,
            open: status === 'failed',
        }
    }, [status])

    return (
        <FlexRow>
            <SideBar />
            <ProfileLink />
            <MainContainer>
                <Outlet />
            </MainContainer>
            <Dialog {...dialogProps} />
        </FlexRow>
    )
}

export default Root
