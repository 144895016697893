import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/authSlice'
import usersReducer from '../features/usersSlice'
import subscriptionsReducer from '../features/subscriptionsSlice'
import versionReducer from '../features/versionSlice'
import userReducer from '../features/userSlice'
import profileReducer from '../features/profileSlice'
import seatAssignmentsReducer from '../features/seatAssignmentsSlice'
import billingReducer from '../features/billingSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const store = configureStore({
    reducer: {
        version: versionReducer,
        auth: authReducer,
        users: usersReducer,
        user: userReducer,
        subscriptions: subscriptionsReducer,
        profile: profileReducer,
        seatAssignments: seatAssignmentsReducer,
        billing: billingReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
