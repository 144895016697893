import { alpha, styled } from '@mui/material'
import { FlexColumn, FlexRow, FlexRowCenter } from './flex'

export const Sidebar = styled(FlexColumn)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    boxShadow: `5px 0 12px 0 ${alpha(theme.palette.common.black, 0.3)}`,
    zIndex: theme.zIndex.drawer + 200,

    '&.open': {
        width: 186,
        minWidth: 186,
    },

    '&.closed': {
        width: 70,
    },
}))

export const SidebarHeader = styled(FlexRowCenter)({
    backgroundColor: '#051935',
    height: 70,

    img: {
        height: 33,
        width: 120,
    },
})

export const SidebarMenu = styled(FlexColumn)({
    flexGrow: 1,
    margin: '31px 0',
    padding: 0,
})

export const SidebarMenuItem = styled('div')(({ theme }) => ({
    'a, span': {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
        textDecoration: 'none',
        height: 50,
        cursor: 'pointer',

        svg: {
            margin: '0 19px',
            width: 32,
            height: 32,
        },

        span: {
            fontSize: 16,
        },

        '&.active': {
            backgroundColor: alpha(theme.palette.common.black, 0.35),

            svg: {
                color: theme.palette.custom.mustard.main,
            },
        },
    },
}))

export const SidebarToggle = styled(FlexRow)(({ theme }) => ({
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    color: theme.palette.common.white,
    marginBottom: 42,

    svg: {
        margin: '0 19px',
        width: 32,
        height: 32,
    },

    span: {
        fontSize: 16,
    },
}))
