import { createTheme } from '@mui/material'
import RegularAimsunMedium from './RegularAimsun-Medium.woff2'
import RegularAimsunBold from './RegularAimsun-Bold.woff2'

export const NAVY_BLUE = '#09254C'
export const RED_LIVE = '#EF442E'
export const SMOKE = '#616161'
export const DARK_GREY = '#4C4C4C'
export const GREY = '#9e9e9e'
export const MEDIUM_GREY = '#A3A3A3'
export const LIGHT_GREY = '#BDBDBD'
export const NEXT_BLUE = '#1F80D4'
export const OLIVE_ISH = '#009186'
export const WYLD_PINK = '#D33479'
export const MUSTARD = '#FFC401'
export const PALE_PINK = '#E4A098'
export const PURPLE_ISH = '#DDCBE3'
export const WHITE = '#FFFFFF'
export const WHITESMOKE = '#F7F7F7'

export const SECTION_SIDEBAR_SELECTED_COLOR = `rgba(${NEXT_BLUE}, 0.2)`

const aimsunLiveTheme = createTheme({
    palette: {
        primary: {
            main: NAVY_BLUE,
        },
        secondary: {
            main: RED_LIVE,
        },
        text: {
            primary: NAVY_BLUE,
        },
        info: {
            main: NEXT_BLUE,
        },
        warning: {
            main: MUSTARD,
        },
        error: {
            main: RED_LIVE,
        },
        custom: {
            smoke: {
                main: SMOKE,
            },
            grey: {
                main: GREY,
            },
            darkGrey: {
                main: DARK_GREY,
            },
            mediumGrey: {
                main: MEDIUM_GREY,
            },
            lightGrey: {
                main: LIGHT_GREY,
            },
            whitesmoke: {
                main: WHITESMOKE,
            },
            mustard: {
                main: MUSTARD,
            },
            blue: {
                main: NEXT_BLUE,
            },
            olive: {
                main: OLIVE_ISH,
            },
            pink: {
                main: WYLD_PINK,
            },
            palePink: {
                main: PALE_PINK,
            },
            purple: {
                main: PURPLE_ISH,
            },
        },
    },
    typography: {
        fontFamily: 'RegularAimsunMedium',
        body1: {
            fontFamily: 'RegularAimsunMedium',
            color: NAVY_BLUE,
            fontSize: 16,
            lineHeight: 1.3,
        },
        body2: {
            fontFamily: 'RegularAimsunMedium',
            color: GREY,
            fontSize: 16,
            lineHeight: 1.3,
        },
        button: {
            fontFamily: 'RegularAimsunBold',
            color: WHITE,
            fontSize: 16,
            textTransform: 'none',
        },
        h2: {
            fontFamily: 'RegularAimsunBold',
            color: NAVY_BLUE,
            fontSize: 18,
            opacity: 1,
        },
        h5: {
            fontFamily: 'RegularAimsunMedium',
            color: SMOKE,
            fontSize: 12,
            opacity: 0.8,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'RegularAimsunMedium';
                    font-style: normal;
                    src: url(${RegularAimsunMedium}) format('woff2');
                }

                @font-face {
                    font-family: 'RegularAimsunBold';
                    font-style: bold;
                    src: url(${RegularAimsunBold}) format('woff2');
                }
          `,
        },
    },
})

export default aimsunLiveTheme
