import { Divider, Skeleton, styled } from '@mui/material'

export const Header = styled('h2')({
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    whiteSpace: 'nowrap',
})

export const Subheader = styled('div')(({ theme }) => ({
    fontSize: 16,
    fontWeight: 400,
    margin: 0,
    whiteSpace: 'nowrap',
    color: theme.palette.custom.mediumGrey.main,
}))

export const SubheaderSplit = styled(Subheader)(({ theme }) => ({
    display: 'flex',
    gap: 100,
    marginTop: 10,
}))

export const SubheaderSkeleton = styled(Skeleton)({
    fontSize: 16,
    margin: 0,
})

export const HeaderDivider = styled(Divider)(({ theme }) => ({
    marginTop: 9,
    marginBottom: 18,
}))
