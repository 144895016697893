import { LicenserClient } from './aimsunsf_grpc_web_pb'
import google_protobuf_empty_pb, { Empty } from 'google-protobuf/google/protobuf/empty_pb.js'
import { setAuthStatus, setAuthTokens } from '../features/authSlice'
import axios from 'axios'
import { parseJwt } from '../utils/utils'
import { store } from '../app/store'
import { RpcError } from 'grpc-web'

proto.AimsunSF.v6 = require('../grpc/aimsunsf_pb.js')

const client = new LicenserClient(process.env.REACT_APP_LICENSER_URL!, null, null)

const getMetadata = () => {
    const state: StoreState = store.getState()
    const timeAllowed = 500
    const deadline: string = new Date(Date.now() + timeAllowed).toISOString()
    const token = process.env.REACT_APP_USE_ID_TOKEN === 'true' ? state.auth.idToken : state.auth.accessToken
    return { token, 'session-id': state.auth.uuid, deadline }
}

export const signOut = (): void => {
    localStorage.clear()
    const url = new URL(process.env.REACT_APP_AUTH_URL!)
    url.searchParams.append('redirect', window.location.href.split('?', 1)[0])
    window.location.href = url.href
}

const handleRefreshToken = () => {
    return new Promise((resolve, reject) => {
        const state: StoreState = store.getState()
        const { accessToken, refreshToken } = state.auth
        store.dispatch(setAuthStatus('refreshing'))
        if (accessToken && refreshToken) {
            const jwtContent: JwtContent = parseJwt(accessToken)
            const params = new URLSearchParams()
            params.append('redirect', window.location.href)
            params.append('refresh_token', refreshToken)
            params.append('scope', jwtContent.scope)
            axios
                .post(`${process.env.REACT_APP_AUTH_URL}/api/token`, params)
                .then(response => {
                    store.dispatch(
                        setAuthTokens({
                            accessToken: response.data.access_token,
                            refreshToken: response.data.refresh_token,
                            idToken: response.data.id_token,
                        })
                    )
                    store.dispatch(setAuthStatus('authenticated'))
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response.data.code === 'NotAuthorizedException') {
                        signOut()
                    }
                    store.dispatch(setAuthStatus('unauthenticated'))
                    reject(error.response.data)
                })
        } else {
            signOut()
        }
    })
}

export const checkToken = (): void => {
    const state: StoreState = store.getState()
    const { accessToken, refreshToken } = state.auth
    const searchParams: URLSearchParams = new URL(window.location.href).searchParams
    if ((!accessToken || !refreshToken) && !searchParams.get('token')) {
        signOut()
    }
    if (searchParams.get('token') || searchParams.get('refresh_token')) {
        store.dispatch(
            setAuthTokens({
                accessToken: searchParams.get('token') || '',
                refreshToken: searchParams.get('refresh_token') || '',
                idToken: searchParams.get('id_token') || '',
            })
        )
        window.location.href = window.location.href.split('?', 1)[0]
    }
}

export const checkTokenExpiration = (): boolean => {
    const state: StoreState = store.getState()
    const { accessToken } = state.auth
    const data: JwtContent = parseJwt(accessToken)
    if (Date.now() >= data.exp * 1000) {
        return true
    }
    return false
}

export const commonCallback: CommonCallbackInputs = <T>(
    resolve: Resolve<T>,
    reject: Reject,
    error: RpcError,
    response: any
): void => {
    if (error) {
        if (process.env.NODE_ENV === 'development') {
            console.log(error.code, error.message)
        }
        const state: StoreState = store.getState()
        const { status } = state.auth
        if (error.code === 16) {
            if (status !== 'refreshing' && checkTokenExpiration()) {
                handleRefreshToken()
                    .then((): void => window.location.reload())
                    .catch((refreshError: string): void => {
                        store.dispatch(setAuthStatus('failed'))
                        reject(refreshError)
                    })
            } else {
                store.dispatch(setAuthStatus('failed'))
            }
        }
        reject(error)
    } else {
        if (process.env.NODE_ENV === 'development') {
            console.log(response.toObject() as T)
        }
        resolve(response.toObject() as T)
    }
}

export const callPing = (): Promise<Version> => {
    return new Promise((resolve: Resolve<Version>, reject: Reject): void => {
        const request = new Empty()
        client.ping(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.Version): void =>
            commonCallback<Version>(resolve, reject, error, response)
        )
    })
}

export const callProfile = (): Promise<Profile> => {
    return new Promise((resolve: Resolve<Profile>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.profile(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.Profile): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUpdateProfile = (data: Profile): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => {
            if (key === 'address') {
                return Object.keys(data.address).map(key => data.address[key as keyof Address])
            } else if (key === 'usertype') {
                return data.usertype
            } else {
                return data[key as keyof Profile]
            }
        })
        const request = new proto.AimsunSF.v6.Profile(dataArray)
        client.updateProfile(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUser = (userId: string): Promise<UserInfo> => {
    return new Promise((resolve: Resolve<UserInfo>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.UserId([userId])
        client.user(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.UserInfo): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUsers = (): Promise<UserResponse> => {
    return new Promise((resolve: Resolve<UserResponse>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.users(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.UserResponse): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callInactiveUsers = (): Promise<UserResponse> => {
    return new Promise((resolve: Resolve<UserResponse>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.inactiveUsers(
            request,
            getMetadata(),
            (error: RpcError, response: proto.AimsunSF.v6.UserResponse): void =>
                commonCallback(resolve, reject, error, response)
        )
    })
}

export const callSubscriptions = (): Promise<SubscriptionsResponse> => {
    return new Promise((resolve: Resolve<SubscriptionsResponse>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.subscriptions(
            request,
            getMetadata(),
            (error: RpcError, response: proto.AimsunSF.v6.SubscriptionsResponse): void =>
                commonCallback(resolve, reject, error, response)
        )
    })
}

export const callActivateUser = (userId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.UserId([userId])
        client.activateUser(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callDeactivateUser = (userId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.UserId([userId])
        client.deactivateUser(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callChangeUserType = (userId: string, userType: number): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.ChangeUserTypeData([userId, userType])
        client.changeUserType(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUserSeatAssignments = (userId: string): Promise<UserSeatAssignmentsResponse> => {
    return new Promise((resolve: Resolve<UserSeatAssignmentsResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.UserId([userId])
        client.userSeatAssignments(
            request,
            getMetadata(),
            (error: RpcError, response: proto.AimsunSF.v6.SeatAssignmentsResponse): void =>
                commonCallback(resolve, reject, error, response)
        )
    })
}

export const callAssignSeat = (
    userId: string,
    subscriptionLineId: string,
    numberOfSeats: number
): Promise<SeatAssignment> => {
    return new Promise((resolve: Resolve<SeatAssignment>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.AssignSeatData([userId, subscriptionLineId, numberOfSeats])
        client.assignSeat(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.SeatAssignment): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUnassignSeat = (userId: string, seatId: string): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<EmptyResponse>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.AssignSeatData([userId, seatId])
        client.unassignSeat(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callAddUser = (addUserData: AddUserData): Promise<UserId> => {
    return new Promise((resolve: Resolve<UserId>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.AddUserData([
            addUserData.email,
            addUserData.salutation,
            addUserData.firstName,
            addUserData.lastName,
        ])
        client.addUser(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.UserId): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callBilling = (): Promise<Billing> => {
    return new Promise((resolve: Resolve<Billing>, reject: Reject): void => {
        const request = new google_protobuf_empty_pb.Empty()
        client.billing(request, getMetadata(), (error: RpcError, response: proto.AimsunSF.v6.Billing): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callUpdateBilling = (data: Billing): Promise<EmptyResponse> => {
    return new Promise((resolve: Resolve<Billing>, reject: Reject): void => {
        const dataArray = Object.keys(data).map(key => {
            if (key === 'address') {
                return Object.keys(data.address).map(key => data.address[key as keyof Address])
            } else {
                return data[key as keyof Billing]
            }
        })
        const request = new proto.AimsunSF.v6.Billing(dataArray)
        client.updateBilling(request, getMetadata(), (error: RpcError, response: Empty): void =>
            commonCallback(resolve, reject, error, response)
        )
    })
}

export const callAccountProfile = (): Promise<AccountProfile> => {
    return new Promise((resolve: Resolve<AccountProfile>, reject: Reject): void => {
        const request = new proto.AimsunSF.v6.AccountProfile()
        client.accountProfile(
            request,
            getMetadata(),
            (error: RpcError, response: proto.AimsunSF.v6.AccountProfile): void =>
                commonCallback(resolve, reject, error, response)
        )
    })
}
