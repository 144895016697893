/**
 * @fileoverview gRPC-Web generated client stub for AimsunSF.v6
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.21.12
// source: aimsunsf.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.AimsunSF = {};
proto.AimsunSF.v6 = require('./aimsunsf_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AimsunSF.v6.LicenserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AimsunSF.v6.LicenserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.Version>}
 */
const methodDescriptor_Licenser_ping = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/ping',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.Version,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.Version.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.Version)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.Version>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.ping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/ping',
      request,
      metadata || {},
      methodDescriptor_Licenser_ping,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.Version>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.ping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/ping',
      request,
      metadata || {},
      methodDescriptor_Licenser_ping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.Email,
 *   !proto.AimsunSF.v6.FederatedResponse>}
 */
const methodDescriptor_Licenser_federated = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/federated',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.Email,
  proto.AimsunSF.v6.FederatedResponse,
  /**
   * @param {!proto.AimsunSF.v6.Email} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.FederatedResponse.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.Email} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.FederatedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.FederatedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.federated =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/federated',
      request,
      metadata || {},
      methodDescriptor_Licenser_federated,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.Email} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.FederatedResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.federated =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/federated',
      request,
      metadata || {},
      methodDescriptor_Licenser_federated);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ActivationData,
 *   !proto.AimsunSF.v6.ActivationResponse>}
 */
const methodDescriptor_Licenser_activate = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/activate',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ActivationData,
  proto.AimsunSF.v6.ActivationResponse,
  /**
   * @param {!proto.AimsunSF.v6.ActivationData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.ActivationResponse.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ActivationData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.ActivationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.ActivationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.activate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activate',
      request,
      metadata || {},
      methodDescriptor_Licenser_activate,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ActivationData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.ActivationResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.activate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activate',
      request,
      metadata || {},
      methodDescriptor_Licenser_activate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ExtendData,
 *   !proto.AimsunSF.v6.ActivationResponse>}
 */
const methodDescriptor_Licenser_extend = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/extend',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ExtendData,
  proto.AimsunSF.v6.ActivationResponse,
  /**
   * @param {!proto.AimsunSF.v6.ExtendData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.ActivationResponse.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ExtendData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.ActivationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.ActivationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.extend =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/extend',
      request,
      metadata || {},
      methodDescriptor_Licenser_extend,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ExtendData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.ActivationResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.extend =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/extend',
      request,
      metadata || {},
      methodDescriptor_Licenser_extend);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ReleaseData,
 *   !proto.AimsunSF.v6.ReleaseResponse>}
 */
const methodDescriptor_Licenser_release = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/release',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ReleaseData,
  proto.AimsunSF.v6.ReleaseResponse,
  /**
   * @param {!proto.AimsunSF.v6.ReleaseData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.ReleaseResponse.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ReleaseData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.ReleaseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.ReleaseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.release =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/release',
      request,
      metadata || {},
      methodDescriptor_Licenser_release,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ReleaseData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.ReleaseResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.release =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/release',
      request,
      metadata || {},
      methodDescriptor_Licenser_release);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.RightsResponse>}
 */
const methodDescriptor_Licenser_rights = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/rights',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.RightsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.RightsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.RightsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.RightsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.rights =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/rights',
      request,
      metadata || {},
      methodDescriptor_Licenser_rights,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.RightsResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.rights =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/rights',
      request,
      metadata || {},
      methodDescriptor_Licenser_rights);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.SeatAssignmentsResponse>}
 */
const methodDescriptor_Licenser_seatAssignments = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/seatAssignments',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.SeatAssignmentsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.SeatAssignmentsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.SeatAssignmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.SeatAssignmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.seatAssignments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/seatAssignments',
      request,
      metadata || {},
      methodDescriptor_Licenser_seatAssignments,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.SeatAssignmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.seatAssignments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/seatAssignments',
      request,
      metadata || {},
      methodDescriptor_Licenser_seatAssignments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.Profile>}
 */
const methodDescriptor_Licenser_profile = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/profile',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.Profile,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.Profile.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.Profile)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.Profile>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.profile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/profile',
      request,
      metadata || {},
      methodDescriptor_Licenser_profile,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.Profile>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.profile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/profile',
      request,
      metadata || {},
      methodDescriptor_Licenser_profile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ACK,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_sendACK = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/sendACK',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ACK,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.ACK} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ACK} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.sendACK =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/sendACK',
      request,
      metadata || {},
      methodDescriptor_Licenser_sendACK,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ACK} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.sendACK =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/sendACK',
      request,
      metadata || {},
      methodDescriptor_Licenser_sendACK);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.TokenForRight,
 *   !proto.AimsunSF.v6.UserToken>}
 */
const methodDescriptor_Licenser_createUserTokenForRight = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/createUserTokenForRight',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.TokenForRight,
  proto.AimsunSF.v6.UserToken,
  /**
   * @param {!proto.AimsunSF.v6.TokenForRight} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.UserToken.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.TokenForRight} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.UserToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.UserToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.createUserTokenForRight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/createUserTokenForRight',
      request,
      metadata || {},
      methodDescriptor_Licenser_createUserTokenForRight,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.TokenForRight} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.UserToken>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.createUserTokenForRight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/createUserTokenForRight',
      request,
      metadata || {},
      methodDescriptor_Licenser_createUserTokenForRight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_validateToken = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/validateToken',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.validateToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/validateToken',
      request,
      metadata || {},
      methodDescriptor_Licenser_validateToken,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.validateToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/validateToken',
      request,
      metadata || {},
      methodDescriptor_Licenser_validateToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.Profile,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_updateProfile = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/updateProfile',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.Profile,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.Profile} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.Profile} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProfile',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProfile,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.Profile} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProfile',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.AccountProfile>}
 */
const methodDescriptor_Licenser_accountProfile = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/accountProfile',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.AccountProfile,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.AccountProfile.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.AccountProfile)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.AccountProfile>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.accountProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/accountProfile',
      request,
      metadata || {},
      methodDescriptor_Licenser_accountProfile,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.AccountProfile>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.accountProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/accountProfile',
      request,
      metadata || {},
      methodDescriptor_Licenser_accountProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ChangeUserTypeData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_changeUserType = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/changeUserType',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ChangeUserTypeData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.ChangeUserTypeData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ChangeUserTypeData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.changeUserType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/changeUserType',
      request,
      metadata || {},
      methodDescriptor_Licenser_changeUserType,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ChangeUserTypeData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.changeUserType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/changeUserType',
      request,
      metadata || {},
      methodDescriptor_Licenser_changeUserType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.UserId,
 *   !proto.AimsunSF.v6.SeatAssignmentsResponse>}
 */
const methodDescriptor_Licenser_userSeatAssignments = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/userSeatAssignments',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.UserId,
  proto.AimsunSF.v6.SeatAssignmentsResponse,
  /**
   * @param {!proto.AimsunSF.v6.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.SeatAssignmentsResponse.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.SeatAssignmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.SeatAssignmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.userSeatAssignments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/userSeatAssignments',
      request,
      metadata || {},
      methodDescriptor_Licenser_userSeatAssignments,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.SeatAssignmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.userSeatAssignments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/userSeatAssignments',
      request,
      metadata || {},
      methodDescriptor_Licenser_userSeatAssignments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.UserId,
 *   !proto.AimsunSF.v6.UserInfo>}
 */
const methodDescriptor_Licenser_user = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/user',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.UserId,
  proto.AimsunSF.v6.UserInfo,
  /**
   * @param {!proto.AimsunSF.v6.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.UserInfo.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.UserInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.UserInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.user =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/user',
      request,
      metadata || {},
      methodDescriptor_Licenser_user,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.UserInfo>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.user =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/user',
      request,
      metadata || {},
      methodDescriptor_Licenser_user);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.UserResponse>}
 */
const methodDescriptor_Licenser_users = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/users',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.UserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.UserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.users =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/users',
      request,
      metadata || {},
      methodDescriptor_Licenser_users,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.UserResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.users =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/users',
      request,
      metadata || {},
      methodDescriptor_Licenser_users);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.UserResponse>}
 */
const methodDescriptor_Licenser_inactiveUsers = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/inactiveUsers',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.UserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.UserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.inactiveUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/inactiveUsers',
      request,
      metadata || {},
      methodDescriptor_Licenser_inactiveUsers,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.UserResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.inactiveUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/inactiveUsers',
      request,
      metadata || {},
      methodDescriptor_Licenser_inactiveUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.SubscriptionsResponse>}
 */
const methodDescriptor_Licenser_subscriptions = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/subscriptions',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.SubscriptionsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.SubscriptionsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.SubscriptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.SubscriptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.subscriptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/subscriptions',
      request,
      metadata || {},
      methodDescriptor_Licenser_subscriptions,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.SubscriptionsResponse>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.subscriptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/subscriptions',
      request,
      metadata || {},
      methodDescriptor_Licenser_subscriptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.AssignSeatData,
 *   !proto.AimsunSF.v6.SeatAssignment>}
 */
const methodDescriptor_Licenser_assignSeat = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/assignSeat',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.AssignSeatData,
  proto.AimsunSF.v6.SeatAssignment,
  /**
   * @param {!proto.AimsunSF.v6.AssignSeatData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.SeatAssignment.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.AssignSeatData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.SeatAssignment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.SeatAssignment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.assignSeat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/assignSeat',
      request,
      metadata || {},
      methodDescriptor_Licenser_assignSeat,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.AssignSeatData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.SeatAssignment>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.assignSeat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/assignSeat',
      request,
      metadata || {},
      methodDescriptor_Licenser_assignSeat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.AssignSeatData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_unassignSeat = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/unassignSeat',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.AssignSeatData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.AssignSeatData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.AssignSeatData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.unassignSeat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/unassignSeat',
      request,
      metadata || {},
      methodDescriptor_Licenser_unassignSeat,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.AssignSeatData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.unassignSeat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/unassignSeat',
      request,
      metadata || {},
      methodDescriptor_Licenser_unassignSeat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.AddUserData,
 *   !proto.AimsunSF.v6.UserId>}
 */
const methodDescriptor_Licenser_addUser = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/addUser',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.AddUserData,
  proto.AimsunSF.v6.UserId,
  /**
   * @param {!proto.AimsunSF.v6.AddUserData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.UserId.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.AddUserData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.UserId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.UserId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.addUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/addUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_addUser,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.AddUserData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.UserId>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.addUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/addUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_addUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.UserId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_deactivateUser = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/deactivateUser',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.UserId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.deactivateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/deactivateUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_deactivateUser,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.deactivateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/deactivateUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_deactivateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.UserId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_activateUser = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/activateUser',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.UserId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.activateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activateUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_activateUser,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.activateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activateUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_activateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.Billing>}
 */
const methodDescriptor_Licenser_billing = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/billing',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.Billing,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.Billing.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.Billing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.Billing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.billing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/billing',
      request,
      metadata || {},
      methodDescriptor_Licenser_billing,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.Billing>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.billing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/billing',
      request,
      metadata || {},
      methodDescriptor_Licenser_billing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.Billing,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_updateBilling = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/updateBilling',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.Billing,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.Billing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.Billing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.updateBilling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateBilling',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateBilling,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.Billing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.updateBilling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateBilling',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateBilling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.MyProject>}
 */
const methodDescriptor_Licenser_projects = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/projects',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.MyProject,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.MyProject.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.MyProject>}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.projects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/AimsunSF.v6.Licenser/projects',
      request,
      metadata || {},
      methodDescriptor_Licenser_projects);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.MyProject>}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.projects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/AimsunSF.v6.Licenser/projects',
      request,
      metadata || {},
      methodDescriptor_Licenser_projects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.AimsunSF.v6.MyProject>}
 */
const methodDescriptor_Licenser_archivedProjects = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/archivedProjects',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.AimsunSF.v6.MyProject,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.MyProject.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.MyProject>}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.archivedProjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/AimsunSF.v6.Licenser/archivedProjects',
      request,
      metadata || {},
      methodDescriptor_Licenser_archivedProjects);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.MyProject>}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.archivedProjects =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/AimsunSF.v6.Licenser/archivedProjects',
      request,
      metadata || {},
      methodDescriptor_Licenser_archivedProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ProjectId,
 *   !proto.AimsunSF.v6.MyProject>}
 */
const methodDescriptor_Licenser_myProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/myProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ProjectId,
  proto.AimsunSF.v6.MyProject,
  /**
   * @param {!proto.AimsunSF.v6.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.MyProject.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.MyProject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.MyProject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.myProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/myProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_myProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.MyProject>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.myProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/myProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_myProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ProjectId,
 *   !proto.AimsunSF.v6.ProjectInfo>}
 */
const methodDescriptor_Licenser_project = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/project',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ProjectId,
  proto.AimsunSF.v6.ProjectInfo,
  /**
   * @param {!proto.AimsunSF.v6.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.ProjectInfo.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.ProjectInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.ProjectInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.project =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/project',
      request,
      metadata || {},
      methodDescriptor_Licenser_project,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.ProjectInfo>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.project =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/project',
      request,
      metadata || {},
      methodDescriptor_Licenser_project);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.Project,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_updateProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/updateProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.Project,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.Project} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.Project} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.Project} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.AddProjectUserData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_addProjectUser = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/addProjectUser',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.AddProjectUserData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.AddProjectUserData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.AddProjectUserData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.addProjectUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/addProjectUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_addProjectUser,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.AddProjectUserData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.addProjectUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/addProjectUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_addProjectUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.RemoveProjectUserData,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_removeProjectUser = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/removeProjectUser',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.RemoveProjectUserData,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.RemoveProjectUserData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.RemoveProjectUserData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.removeProjectUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/removeProjectUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_removeProjectUser,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.RemoveProjectUserData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.removeProjectUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/removeProjectUser',
      request,
      metadata || {},
      methodDescriptor_Licenser_removeProjectUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.UpdateProjectUserRole,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_updateProjectUserRole = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/updateProjectUserRole',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.UpdateProjectUserRole,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.updateProjectUserRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProjectUserRole',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProjectUserRole,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.UpdateProjectUserRole} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.updateProjectUserRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/updateProjectUserRole',
      request,
      metadata || {},
      methodDescriptor_Licenser_updateProjectUserRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.CreateProject,
 *   !proto.AimsunSF.v6.ProjectId>}
 */
const methodDescriptor_Licenser_createProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/createProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.CreateProject,
  proto.AimsunSF.v6.ProjectId,
  /**
   * @param {!proto.AimsunSF.v6.CreateProject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.AimsunSF.v6.ProjectId.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.CreateProject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.AimsunSF.v6.ProjectId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.AimsunSF.v6.ProjectId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/createProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_createProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.CreateProject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.AimsunSF.v6.ProjectId>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/createProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_createProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ProjectId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_archiveProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/archiveProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ProjectId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.archiveProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/archiveProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_archiveProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.archiveProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/archiveProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_archiveProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ProjectId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_activateProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/activateProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ProjectId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.activateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activateProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_activateProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.activateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/activateProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_activateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AimsunSF.v6.ProjectId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Licenser_removeProject = new grpc.web.MethodDescriptor(
  '/AimsunSF.v6.Licenser/removeProject',
  grpc.web.MethodType.UNARY,
  proto.AimsunSF.v6.ProjectId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.AimsunSF.v6.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AimsunSF.v6.LicenserClient.prototype.removeProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/removeProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_removeProject,
      callback);
};


/**
 * @param {!proto.AimsunSF.v6.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.AimsunSF.v6.LicenserPromiseClient.prototype.removeProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/AimsunSF.v6.Licenser/removeProject',
      request,
      metadata || {},
      methodDescriptor_Licenser_removeProject);
};


module.exports = proto.AimsunSF.v6;

