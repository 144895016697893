import { selectBillingStatus, selectBilling, selectBillingModified, selectBillingError } from '../app/selectors'
import { useAppDispatch, useAppSelector } from '../app/store'
import { AlertColor, CircularProgress, FormControl, MenuItem, SelectChangeEvent } from '@mui/material'
import { useEffect, useMemo, useCallback, useState } from 'react'
import { fetchBilling, setBillingModified, resetBilling, updateBilling } from '../features/billingSlice'
import StandardButton from '../styles/button'
import { FlexColumn } from '../styles/flex'
import { Spacing, SpacingM, SpacingS } from '../styles/spacing'
import { PlainTextField } from '../styles/textfield'
import { Buttons } from '../styles/user-panel'
import { StandardAlert } from '../styles/alert'
import StandardSnackbar from '../styles/snackbar'
import { Header, Subheader } from '../styles/header'
import StandardInputLabel from '../styles/label'
import { PlainSelect } from '../styles/select'
import countries from '../assets/countries.json'

const BillingAccount = (): React.ReactElement => {
    const dispatch = useAppDispatch()
    const billing: Billing = useAppSelector(selectBilling)
    const billingModified: Billing = useAppSelector(selectBillingModified)
    const status: string = useAppSelector(selectBillingStatus)
    const error = useAppSelector(selectBillingError)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

    useEffect(() => {
        if (status === 'not_loaded') {
            dispatch(fetchBilling())
        } else if (status === 'success' || status === 'failed') {
            setShowSnackbar(true)
        }
    }, [dispatch, status])

    const loaded: boolean = useMemo((): boolean => status !== 'not_loaded' && status !== 'loading', [status])
    const isChanged: boolean = useMemo(
        () => JSON.stringify(billing) !== JSON.stringify(billingModified),
        [billing, billingModified]
    )
    const isUpdating: boolean = useMemo(() => status === 'loading', [status])
    const showProvince: boolean = process.env.REACT_APP_SHOW_PROVINCE === 'true'

    const handleTextfield = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.target.id === 'email') {
                dispatch(setBillingModified({ ...billingModified, email: event.target.value }))
            } else if (event.target.id === 'taxid') {
                dispatch(setBillingModified({ ...billingModified, taxid: event.target.value }))
            } else if (event.target.id === 'address.street') {
                dispatch(
                    setBillingModified({
                        ...billingModified,
                        address: { ...billingModified.address, street: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.postalcode') {
                dispatch(
                    setBillingModified({
                        ...billingModified,
                        address: { ...billingModified.address, postalcode: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.city') {
                dispatch(
                    setBillingModified({
                        ...billingModified,
                        address: { ...billingModified.address, city: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.province') {
                dispatch(
                    setBillingModified({
                        ...billingModified,
                        address: { ...billingModified.address, province: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.country') {
                dispatch(
                    setBillingModified({
                        ...billingModified,
                        address: { ...billingModified.address, country: event.target.value },
                    })
                )
            }
        },
        [dispatch, billingModified]
    )

    const handleCountryChange = (event: SelectChangeEvent<unknown>) => {
        dispatch(
            setBillingModified({
                ...billingModified,
                address: { ...billingModified.address, countrycode: event.target.value as string },
            })
        )
    }

    const handleCancel = () => {
        dispatch(resetBilling())
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (isChanged) {
            dispatch(updateBilling(billingModified))
        }
    }

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return 'Billing updated successfully'
    }, [error])

    const alertSeverity = useMemo((): AlertColor => {
        return error ? 'error' : 'success'
    }, [error])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    return (
        <FlexColumn>
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <form onSubmit={handleSubmit}>
                    <FlexColumn>
                        <Header>Billing email</Header>
                        <Subheader>
                            Your monthly invoice will be sent to this email. This should be the legal email of your
                            business.
                        </Subheader>
                        <SpacingS />
                        <PlainTextField
                            id='email'
                            label='Email'
                            value={billingModified.email}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                        <SpacingM />
                        <Header>Billing address</Header>
                        <Subheader>
                            This address appears on your monthly invoice and should be the legal address of your
                            business.
                        </Subheader>
                        <SpacingS />
                        <PlainTextField
                            id='address.street'
                            label='Street'
                            value={billingModified.address.street}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />

                        <PlainTextField
                            id='address.city'
                            label='City'
                            value={billingModified.address.city}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                        {showProvince && (
                            <PlainTextField
                                id='address.province'
                                label='Province'
                                value={billingModified.address.province}
                                variant='standard'
                                disabled={isUpdating}
                                onChange={handleTextfield}
                            />
                        )}
                        <FormControl variant='standard'>
                            <StandardInputLabel id='role-label'>Country</StandardInputLabel>
                            <PlainSelect
                                id='address.country'
                                labelId='country-label'
                                label='Country'
                                value={billingModified.address.countrycode}
                                disabled={isUpdating}
                                displayEmpty
                                onChange={handleCountryChange}>
                                <MenuItem value=''>
                                    <em>Select country</em>
                                </MenuItem>
                                {countries.map(country => (
                                    <MenuItem key={`country-${country.code}`} value={country.code}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </PlainSelect>
                        </FormControl>
                        <PlainTextField
                            id='address.postalcode'
                            label='Postal code'
                            value={billingModified.address.postalcode}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                        <SpacingM />
                        <Header>Tax ID</Header>
                        <Subheader>
                            Registered business can enter their tax identification number to remove tax charges from
                            future bills.
                        </Subheader>
                        <SpacingS />
                        <PlainTextField
                            id='taxid'
                            label='Tax ID'
                            value={billingModified.taxid}
                            variant='standard'
                            disabled={isUpdating}
                            onChange={handleTextfield}
                        />
                    </FlexColumn>
                    <Spacing />
                    <Buttons>
                        <StandardButton variant='outlined' onClick={handleCancel}>
                            Cancel
                        </StandardButton>
                        <StandardButton type='submit' disabled={!isChanged || isUpdating}>
                            Save
                        </StandardButton>
                    </Buttons>
                </form>
            )}
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
        </FlexColumn>
    )
}

export default BillingAccount
