import { PersonRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ProfileHeaderContainer, ProfileHeaderEmail, ProfileHeaderName } from '../styles/profile-header'
import { getName } from '../utils/utils'

const ProfileHeader = ({ profile }: ProfileHeaderProps) => {
    return (
        <ProfileHeaderContainer>
            <PersonRounded sx={{ fontSize: 50 }} />
            <Box>
                <ProfileHeaderName>{getName(profile)}</ProfileHeaderName>
                <ProfileHeaderEmail>{profile.email}</ProfileHeaderEmail>
            </Box>
        </ProfileHeaderContainer>
    )
}

export default ProfileHeader
