import { Box, IconButton } from '@mui/material'
import ProfileHeader from './profile-header'
import { useCallback, useState } from 'react'
import { ClosePanel, UserPanelContainer } from '../styles/user-panel'
import TabPanel from './tab-panels/tab-panel'
import ManageAccountPanel from './tab-panels/manage-account-panel'
import ManageProductsPanel from './tab-panels/manage-products-panel'
import { StandardTab, StandardTabs } from '../styles/tabs'
import { Close } from '@mui/icons-material'
import { StandardDrawer } from '../styles/dialog'
import { selectUserProfile } from '../app/selectors'
import { useAppSelector } from '../app/store'

const UserPanel = ({ handleClose }: UserPanelProps) => {
    const profile: Profile = useAppSelector(selectUserProfile)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [changed, setChanged] = useState<boolean>(false)

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    const handleClosing = useCallback(() => {
        handleClose(changed)
    }, [changed, handleClose])

    return (
        <StandardDrawer anchor='right' open={true} onClose={handleClosing}>
            <UserPanelContainer>
                <ClosePanel>
                    <IconButton onClick={handleClosing}>
                        <Close />
                    </IconButton>
                </ClosePanel>
                <ProfileHeader profile={profile}></ProfileHeader>
                <Box>
                    <Box>
                        <StandardTabs value={selectedTab} onChange={handleChange} aria-label='manage account tabs'>
                            <StandardTab label='Profile' {...a11yProps(0)} />
                            <StandardTab label='Subscriptions' {...a11yProps(1)} />
                        </StandardTabs>
                    </Box>

                    <TabPanel value={selectedTab} index={0}>
                        <ManageAccountPanel handleChanged={setChanged} handleClosing={handleClosing} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <ManageProductsPanel handleChanged={setChanged} handleClosing={handleClosing} />
                    </TabPanel>
                </Box>
            </UserPanelContainer>
        </StandardDrawer>
    )
}

export default UserPanel
