import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callProfile } from '../grpc/api'

const initialState: ProfileState = {
    profile: {
        cognitoid: 'cognitoid',
        email: 'user@server.net',
        firstname: 'Name',
        lastname: 'LastName',
        salutation: 'Mr.',
        userid: 'userid',
        usertype: 0,
        address: {
            city: 'City',
            country: 'Country',
            countrycode: 'Country',
            postalcode: '00001',
            province: 'Province',
            street: 'Fake Street',
        },
    },
    status: 'not_loaded',
    error: null,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers(builder): void {
        builder
            .addCase(fetchProfile.pending, (state: ProfileState): void => {
                state.status = 'loading'
            })
            .addCase(fetchProfile.fulfilled, (state: ProfileState, action: PayloadAction<Profile, string>): void => {
                state.profile = action.payload
                state.error = null
                state.status = 'loaded'
            })
            .addCase(fetchProfile.rejected, (state: ProfileState, action): void => {
                state.error = action.error
                state.status = 'failed'
            })
    },
})

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (): Promise<Profile> => {
    return await callProfile()
})

export default profileSlice.reducer
