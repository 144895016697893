import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { signOut } from '../grpc/api'
import StandardButton from '../styles/button'
import StandardDialog, { DialogContainer } from '../styles/dialog'
import { PlainTextField } from '../styles/textfield'

const Dialog = ({
    dialogType,
    title,
    message,
    open,
    defaultValue,
    handleOpen,
    handleConfirm,
    min,
    max,
}: ModalDialogProps) => {
    const [value, setValue] = useState<string | object | undefined>(defaultValue)

    const handleClose = useCallback(() => {
        if (handleOpen) {
            handleOpen(false)
            setTimeout(() => setValue(defaultValue), 500)
        }
    }, [handleOpen, defaultValue])

    const handleConfirmation = useCallback(() => {
        if (handleConfirm) {
            handleConfirm(value as string)
        }
        handleClose()
    }, [handleConfirm, handleClose, value])

    const confirmationText = useMemo(() => {
        if (dialogType === 'seat-number') {
            return 'Assign'
        }
        return 'Confirm'
    }, [dialogType])

    const handleTextfield = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (dialogType === 'seat-number') {
            if (event.target.value.length) {
                const int: number = parseInt(event.target.value)
                if (min !== undefined && int >= min && max !== undefined && int <= max) {
                    setValue(event.target.value)
                }
            } else {
                setValue(event.target.value)
            }
        } else {
            setValue(event.target.value)
        }
    }

    const isAddDisabled = useMemo(() => {
        const form = value as any
        return !form || !form.firstName || !form.lastName || !form.email
    }, [value])

    const showCancel: boolean = dialogType !== 'alert' && dialogType !== 'sign-out'

    return (
        <StandardDialog
            open={open}
            onClose={dialogType !== 'permanent' ? handleClose : undefined}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogContainer>
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
                    {dialogType === 'seat-number' && (
                        <PlainTextField
                            type='number'
                            id='seat-number'
                            label='Seats'
                            value={value}
                            variant='standard'
                            onChange={handleTextfield}
                        />
                    )}
                </DialogContent>
                {dialogType !== 'permanent' && (
                    <DialogActions>
                        {showCancel && (
                            <StandardButton variant='outlined' onClick={handleClose}>
                                Cancel
                            </StandardButton>
                        )}
                        {dialogType === 'alert' && <StandardButton onClick={handleClose}>Close</StandardButton>}
                        {dialogType !== 'alert' && dialogType !== 'sign-out' && dialogType !== 'add-user' && (
                            <StandardButton onClick={handleConfirmation}>{confirmationText}</StandardButton>
                        )}
                        {dialogType === 'sign-out' && <StandardButton onClick={signOut}>Sign in</StandardButton>}
                        {dialogType === 'add-user' && (
                            <StandardButton onClick={handleConfirmation} disabled={isAddDisabled}>
                                Add
                            </StandardButton>
                        )}
                    </DialogActions>
                )}
            </DialogContainer>
        </StandardDialog>
    )
}

export default Dialog
