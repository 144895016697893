import { UnfoldMore } from '@mui/icons-material'
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, Tooltip } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

const BulkActions = ({ label, options, disabled, handleOptionClick }: BulkActionsProps) => {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorElement)

    const showDropdown = useMemo((): boolean => {
        return options.length > 1
    }, [options])

    const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setAnchorElement(null)
    }, [])

    const handleOptionClickAndClose = useCallback(
        (option: BulkActionOption) => {
            handleOptionClick(option)
            handleClose()
        },
        [handleOptionClick, handleClose]
    )

    return (
        <>
            {!showDropdown && (
                <Tooltip title={options[0].label} placement='left'>
                    <span>
                        <IconButton
                            onClick={() => handleOptionClick(options[0])}
                            disabled={disabled}
                            color={options[0].color}
                            id={options[0].id}>
                            <SvgIcon component={options[0].icon} inheritViewBox />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
            {showDropdown && (
                <Button
                    id='bulk-actions'
                    aria-controls={open ? 'bulk-actions-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                    endIcon={<UnfoldMore />}
                    disabled={disabled}>
                    {label}
                </Button>
            )}

            <Menu
                id='bulk-actions-menu'
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'bulk-actions',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                {options.map(option => (
                    <MenuItem
                        key={option.id}
                        id={option.id}
                        title={option.label}
                        color={option.color}
                        onClick={() => handleOptionClickAndClose(option)}>
                        <ListItemIcon>
                            <SvgIcon component={option.icon} fontSize='small' color={option.color} inheritViewBox />
                        </ListItemIcon>
                        <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default BulkActions
